import './UnsupportedBrowser.scss';
import React from 'react';
import Logo from '../elements/Logo.js';
import TextBody from '../elements/TextBody.js';
import Content from '../elements/Content.js';

const UnsupportedBrowser = () => {
  return (
    <div className="unsupported-browser">
      <div className="unsupported-browser-info">
        <div className="unsupported-browser-info__logo">
          <Logo />
        </div>

        <h1 className="unsupported-browser-info__title">
          This web browser is not supported!
        </h1>
      </div>

      <Content>
        <TextBody>
          The web browser you are using is not supported for use with the Health
          &amp; Her Clinic. To access the Clinic, please download and use{' '}
          <a
            href="https://www.google.com/chrome/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome
          </a>
          .
        </TextBody>

        <TextBody>
          For help or further information please contact{' '}
          <a href="mailto:clinic@healthandher.com">clinic@healthandher.com</a>.
        </TextBody>
      </Content>
    </div>
  );
};

export default UnsupportedBrowser;
