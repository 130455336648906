import './FormError.scss';
import PropTypes from 'prop-types';
import React from 'react';

const FormError = (props) => {
  const { children } = props;

  const classes = ['form-error'];

  return <div className={classes.join(' ')}>{children}</div>;
};

FormError.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default FormError;
