import './AppRouter.scss';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import UnauthenticatedRoute from './UnauthenticatedRoute.js';
import AuthenticationRequiredRoute from './AuthenticationRequiredRoute.js';
import ScrollToTop from './ScrollToTop.js';
import LoadingSpinner from '../components/elements/LoadingSpinner.js';
import Activate from '../components/auth/activate/Activate.js';
import Login from '../components/auth/login/Login.js';
import ForgottenPassword from '../components/auth/forgottenPassword/ForgottenPassword.js';
import Home from '../components/home/Home.js';
import History from '../components/history/History.js';
import Account from '../components/account/Account.js';
import AppointmentView from '../components/appointment/View.js';
import UnsupportedBrowser from '../components/general/UnsupportedBrowser.js';
import PageNotFound from '../components/general/PageNotFound.js';
import {
  fetchPractitioner,
  selectPractitionerFetched,
} from '../slices/practitionerSlice.js';
import { isSupportedBrowser } from '../services/browser.js';

const AppRouter = () => {
  const patientFetched = useSelector(selectPractitionerFetched);
  const dispatch = useDispatch();
  const showUnsupportedBrowser = !isSupportedBrowser();

  // Load the initial account state
  useEffect(() => {
    if (showUnsupportedBrowser) {
      return;
    }

    dispatch(fetchPractitioner());
  }, [dispatch, showUnsupportedBrowser]);

  const renderLoading = () => {
    return <LoadingSpinner fullscreen />;
  };

  const renderAuthenticationRequiredRoutes = () => {
    return (
      <Switch>
        {/* Public routes - authentication not required but should logout the user
        first if they are logged in */}
        <UnauthenticatedRoute
          exact
          path="/practitioner/P4hR9rfXEVpoprOs6m0pJR9LultTHbO1/activate-account"
          component={Activate}
        />
        <UnauthenticatedRoute
          exact
          path="/practitioner/P4hR9rfXEVpoprOs6m0pJR9LultTHbO1/login"
          component={Login}
        />
        <UnauthenticatedRoute
          exact
          path="/practitioner/P4hR9rfXEVpoprOs6m0pJR9LultTHbO1/forgotten-password"
          component={ForgottenPassword}
        />
        {/* Private routes - authentication required */}
        <AuthenticationRequiredRoute
          exact
          path="/practitioner/P4hR9rfXEVpoprOs6m0pJR9LultTHbO1/"
          component={Home}
        />
        <AuthenticationRequiredRoute
          exact
          path="/practitioner/P4hR9rfXEVpoprOs6m0pJR9LultTHbO1/history"
          component={History}
        />
        <AuthenticationRequiredRoute
          exact
          path="/practitioner/P4hR9rfXEVpoprOs6m0pJR9LultTHbO1/account"
          component={Account}
        />
        <AuthenticationRequiredRoute
          exact
          path="/practitioner/P4hR9rfXEVpoprOs6m0pJR9LultTHbO1/appointments/:appointmentId"
          component={AppointmentView}
        />
        <Route component={PageNotFound} />
      </Switch>
    );
  };

  let content;
  if (showUnsupportedBrowser) {
    content = <UnsupportedBrowser />;
  } else if (patientFetched) {
    content = renderAuthenticationRequiredRoutes();
  } else {
    content = renderLoading();
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      {content}
    </BrowserRouter>
  );
};

export default AppRouter;
