import './PasswordAndConfirm.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import TextInput from '../elements/form/TextInput.js';

const PasswordAndConfirm = (props) => {
  const { errors, register, watch, getValues, trigger, labels } = props;
  const [showPasswordPlainText, setShowPasswordPlainText] = useState(false);

  // Trigger validation when value changes. We don't want to set the form
  // to validate on the change of all fields, so we are manually
  // triggering the validation on just these fields.
  const newPassword = watch('newPassword');
  if (newPassword !== '') {
    trigger('newPassword');
  }

  const confirmNewPassword = watch('confirmNewPassword');
  if (confirmNewPassword !== '') {
    trigger('confirmNewPassword');
  }

  const onClickPasswordToggle = () => {
    setShowPasswordPlainText(!showPasswordPlainText);
  };

  const PasswordIcon = showPasswordPlainText
    ? VisibilityOffIcon
    : VisibilityIcon;

  const inputType = showPasswordPlainText ? 'text' : 'password';

  return (
    <>
      <div className="password-and-confirm__password">
        <TextInput
          className="password-and-confirm__input"
          label={labels[0]}
          type={inputType}
          name="newPassword"
          error={errors.newPassword}
          registerRef={register({
            required: 'This field is required',
            maxLength: {
              value: 200,
              message: 'Too many characters (max 200)',
            },
            validate: {
              strongPassword: (value) => {
                const passwordErrors = [];

                const oneLowercaseLetter = new RegExp('^(?=.*[a-z])');
                if (oneLowercaseLetter.test(value) === false) {
                  passwordErrors.push('Must contain 1 lowercase letter');
                }

                const oneUppercaseLetter = new RegExp('^(?=.*[A-Z])');
                if (oneUppercaseLetter.test(value) === false) {
                  passwordErrors.push('Must contain 1 uppercase letter');
                }

                const oneNumber = new RegExp('^(?=.*[0-9])');
                if (oneNumber.test(value) === false) {
                  passwordErrors.push('Must contain 1 number');
                }
                const oneSpecialCharacter = new RegExp('^(?=.*[!@#$%^&*£])');
                if (oneSpecialCharacter.test(value) === false) {
                  passwordErrors.push(
                    'Must contain 1 special character (!@#$%^&*£)'
                  );
                }

                if (value.length < 10) {
                  passwordErrors.push('Must be at least 10 characters long');
                }

                if (value.length > 200) {
                  passwordErrors.push(
                    'Must be no more than 200 characters long'
                  );
                }

                return passwordErrors.length === 0 || passwordErrors.join('\n');
              },
            },
          })}
          required
        />
        <PasswordIcon
          className="password-and-confirm__icon"
          onClick={onClickPasswordToggle}
        />
      </div>

      <TextInput
        label={labels[1]}
        type={inputType}
        name="confirmNewPassword"
        error={errors.confirmNewPassword}
        registerRef={register({
          required: 'This field is required',
          maxLength: {
            value: 200,
            message: 'Too many characters (max 200)',
          },
          validate: {
            passwordsMatch: (value) => {
              return (
                value === getValues('newPassword') ||
                'Passwords are not the same'
              );
            },
          },
        })}
        required
      />
    </>
  );
};

PasswordAndConfirm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string),
  getValues: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
};

PasswordAndConfirm.defaultProps = {
  labels: ['New Password', 'Confirm New Password'],
};

export default PasswordAndConfirm;
