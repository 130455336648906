import './Sidebar.scss';
import React from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useSelector } from 'react-redux';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { Link } from 'react-router-dom';
import { selectAll as medicalHistoriesSelectAll } from '../../slices/medicalHistoriesSlice.js';
import { selectAll as menopauseSymptomsSelectAll } from '../../slices/menopauseSymptomsSlice.js';
import { selectAll as medicalProceduresSelectAll } from '../../slices/medicalProceduresSlice.js';
import { selectAll as contraceptionsSelectAll } from '../../slices/contraceptionsSlice.js';
import { selectAllItems as hrtSelectAllItems } from '../../slices/hrtsSlice.js';
import { AppointmentStatuses } from '../../slices/appointmentsSlice.js';

dayjs.extend(advancedFormat);

const Sidebar = (props) => {
  const { appointment, relatedAppointments, patient } = props;
  const medicalHistories = useSelector(medicalHistoriesSelectAll);
  const menopauseSymptoms = useSelector(menopauseSymptomsSelectAll);
  const medicalProcedures = useSelector(medicalProceduresSelectAll);
  const contraceptions = useSelector(contraceptionsSelectAll);
  const hrtItems = useSelector(hrtSelectAllItems);

  // During April 2021, the patient appointment booking process was extended significantly
  // to include a lot more questions. If the patient has entered information for the attribute
  // below then we should show answers for all the extra questions
  const showExtendedPatientDetails = patient.heightFeet !== undefined;

  const renderItemNone = (label = 'None') => {
    return (
      <div className="appointment-view-sidebar-patient-medical__item">
        <CancelIcon className="appointment-view-sidebar-patient-medical__icon appointment-view-sidebar-patient-medical__icon--none" />
        {label}
      </div>
    );
  };

  const renderItemChecked = (label) => {
    return (
      <div
        key={`checked-${label}`}
        className="appointment-view-sidebar-patient-medical__item"
      >
        <CheckCircleIcon className="appointment-view-sidebar-patient-medical__icon appointment-view-sidebar-patient-medical__icon--checked" />{' '}
        {label}
      </div>
    );
  };

  const getItemById = (list, id) => {
    return list.find((el) => el._id === id);
  };

  const renderPatientAppointments = () => {
    const previousAppointments = relatedAppointments
      .filter((el) => {
        const statuses = [
          AppointmentStatuses.COMPLETED,
          AppointmentStatuses.PATIENT_NO_SHOW,
        ];
        const isForPatient = el.patientId === appointment.patientId;
        return isForPatient && statuses.includes(el.status);
      })
      .sort((a, b) => (dayjs(a.startAt).isBefore(b.startAt) ? 1 : -1));

    const upcomingAppointments = relatedAppointments
      .filter((el) => {
        const statuses = [
          AppointmentStatuses.BOOKED,
          AppointmentStatuses.IN_PROGRESS,
          AppointmentStatuses.WAITING_ON_PRACTITIONER,
        ];
        const isForPatient = el.patientId === appointment.patientId;
        return isForPatient && statuses.includes(el.status);
      })
      .sort((a, b) => (dayjs(a.startAt).isBefore(b.startAt) ? -1 : 1));

    const previousAppointmentsList = previousAppointments.map((el) => {
      return (
        <div key={el._id}>
          <Link
            to={`/practitioner/P4hR9rfXEVpoprOs6m0pJR9LultTHbO1/appointments/${el._id}`}
            className="appointment-view-sidebar-patient-medical__link"
          >
            {dayjs(el.startAt).format('ddd Do MMM h:mm A')}
          </Link>
        </div>
      );
    });

    const upcomingAppointmentsList = upcomingAppointments.map((el) => {
      return (
        <div key={el._id}>
          <Link
            to={`/practitioner/P4hR9rfXEVpoprOs6m0pJR9LultTHbO1/appointments/${el._id}`}
            className="appointment-view-sidebar-patient-medical__link"
          >
            {dayjs(el.startAt).format('ddd Do MMM h:mm A')}
          </Link>
        </div>
      );
    });

    return (
      <>
        <div className="appointment-view-sidebar-patient-medical">
          <div className="appointment-view-sidebar-patient-medical__title">
            Booked Appointments
          </div>
          {upcomingAppointments.length > 0
            ? upcomingAppointmentsList
            : renderItemNone()}
        </div>

        <div className="appointment-view-sidebar-patient-medical">
          <div className="appointment-view-sidebar-patient-medical__title">
            Completed Appointments
          </div>
          {previousAppointments.length > 0
            ? previousAppointmentsList
            : renderItemNone()}
        </div>
      </>
    );
  };

  const renderLifestyle = () => {
    if (!showExtendedPatientDetails) return null;

    let smokingLabel = patient.smokingStatus.replace(/_/g, ' ').toLowerCase();
    smokingLabel = smokingLabel.charAt(0).toUpperCase() + smokingLabel.slice(1);

    return (
      <>
        <div className="appointment-view-sidebar-patient-medical">
          <div className="appointment-view-sidebar-patient-medical__title">
            Smoking Status
          </div>

          {patient.smokingStatus === 'CURRENT_SMOKER'
            ? renderItemChecked(smokingLabel)
            : renderItemNone(smokingLabel)}
        </div>
        <div className="appointment-view-sidebar-patient-medical">
          <div className="appointment-view-sidebar-patient-medical__title">
            Alcohol Consumption
          </div>

          {patient.alcoholStatus === 'YES'
            ? renderItemChecked('Drinks Alcohol')
            : renderItemNone("Doesn't Drink Alcohol")}

          {patient.alcoholStatus === 'YES' ? (
            <>
              <p>Units per week: {patient.alcoholUnitPerWeek}</p>
              <p>
                Alcohol free nights per week: {patient.alcoholFreeNightsPerWeek}
              </p>
            </>
          ) : null}
        </div>
      </>
    );
  };

  const renderMenstrualCycle = () => {
    if (!showExtendedPatientDetails) return null;
    return (
      <div className="appointment-view-sidebar-patient-medical">
        <div className="appointment-view-sidebar-patient-medical__title">
          Menstrual Cycle
        </div>
        {patient.lastPeriodSelectedDate ? (
          <>
            Last period: {patient.lastPeriodRange} months ago <br /> (entered{' '}
            {dayjs(patient.lastPeriodSelectedDate).format('d MMMM YYYY')})
          </>
        ) : (
          <>Last period: {dayjs(patient.lastPeriodAt).format('MMMM YYYY')}</>
        )}
      </div>
    );
  };

  const renderSmearTest = () => {
    if (!showExtendedPatientDetails) return null;

    return (
      <div className="appointment-view-sidebar-patient-medical">
        <div className="appointment-view-sidebar-patient-medical__title">
          Cervical Smear Test
        </div>
        {patient.hadSmearTest === 'YES'
          ? renderItemChecked('Tested')
          : renderItemNone('Not Tested')}

        {patient.hadSmearTest === 'YES' &&
          `Last smear test:  ${dayjs(patient.lastSmearTestAt).format(
            'MMMM YYYY'
          )}`}
      </div>
    );
  };

  const renderMenopauseSymptoms = () => {
    if (!showExtendedPatientDetails) return null;

    const menopauseSymptomsList = patient.menopauseSymptoms.map((el) => {
      if (el === 'NONE') {
        return renderItemNone();
      }
      const item = getItemById(menopauseSymptoms, el);
      return renderItemChecked(item.name);
    });

    return (
      <div className="appointment-view-sidebar-patient-medical">
        <div className="appointment-view-sidebar-patient-medical__title">
          Menopause Symptoms
        </div>
        {menopauseSymptomsList.length > 0
          ? menopauseSymptomsList
          : renderItemNone()}
      </div>
    );
  };

  const renderMedicalProceduresLegacy = () => {
    let label = '';
    let additionalLabel = '';
    if (patient.medicalProcedure === 'NONE') {
      label = renderItemNone('None');
    } else if (patient.medicalProcedure === 'OTHER') {
      label = renderItemChecked('Other');
      additionalLabel = patient.medicalProcedureOther;
    } else {
      const item = getItemById(medicalProcedures, patient.medicalProcedure);
      label = renderItemChecked(item?.name ? item.name : 'None');
      additionalLabel = patient.medicalProcedureExtra;
    }

    return (
      <div className="appointment-view-sidebar-patient-medical">
        <div className="appointment-view-sidebar-patient-medical__title">
          Medical Procedures
        </div>
        {label}
        {additionalLabel.length > 0 &&
          `Additional Information: ${additionalLabel}`}
      </div>
    );
  };

  const renderMedicalProcedures = () => {
    if (!showExtendedPatientDetails) return null;

    if (!patient.medicalProcedures && patient.medicalProcedure) {
      return renderMedicalProceduresLegacy();
    }
    const renderMedicalProceduresList = () => {
      let label = [];
      if (!patient.medicalProcedures || !patient.medicalProcedures.length) {
        label = renderItemNone('None');
      } else {
        patient.medicalProcedures.forEach((medicalProcedure) => {
          const item = getItemById(medicalProcedures, medicalProcedure);
          label.push(renderItemChecked(item.name));
          if (
            patient.medicalProcedureDetails &&
            patient.medicalProcedureDetails[item._id]
          ) {
            label.push(
              <small
                key={patient.medicalProcedureDetails[item._id]}
              >{`Why was this done and when: ${
                patient.medicalProcedureDetails[item._id]
              }`}</small>
            );
          }
        });
      }
      return label;
    };
    return (
      <div className="appointment-view-sidebar-patient-medical">
        <div className="appointment-view-sidebar-patient-medical__title">
          Medical Procedures
        </div>
        {renderMedicalProceduresList()}
      </div>
    );
  };

  const renderMedicalHistory = () => {
    const medicalHistoryList = patient.medicalHistory.map((el) => {
      if (el === 'NONE') {
        return renderItemNone();
      }
      const item = getItemById(medicalHistories, el);
      return renderItemChecked(item.name);
    });

    if (patient.medicalHistoryOther && patient.medicalHistoryOther.length > 0) {
      medicalHistoryList.push(renderItemChecked(patient.medicalHistoryOther));
    }

    return (
      <div className="appointment-view-sidebar-patient-medical">
        <div className="appointment-view-sidebar-patient-medical__title">
          Medical History
        </div>
        {medicalHistoryList.length > 0 ? medicalHistoryList : renderItemNone()}
      </div>
    );
  };

  const renderContraception = () => {
    const contraceptionList = patient.takingContraceptionDetails.map((el) => {
      const item = getItemById(contraceptions, el);
      return renderItemChecked(item.name);
    });

    if (
      patient.takingContraceptionOther &&
      patient.takingContraceptionOther.length > 0
    ) {
      contraceptionList.push(
        renderItemChecked(patient.takingContraceptionOther)
      );
    }

    return (
      <div className="appointment-view-sidebar-patient-medical">
        <div className="appointment-view-sidebar-patient-medical__title">
          Contraception
        </div>
        {contraceptionList.length > 0 ? contraceptionList : renderItemNone()}
      </div>
    );
  };

  const renderAllergies = () => {
    const allergiesList = patient.hasAllergiesDetails.map((el) => {
      return renderItemChecked(el);
    });

    let hasAllergiesLabel;
    if (patient.hasAllergies === 'DONT_KNOW') {
      hasAllergiesLabel = 'Dont Know';
    }

    return (
      <div className="appointment-view-sidebar-patient-medical">
        <div className="appointment-view-sidebar-patient-medical__title">
          Allergies
        </div>
        {allergiesList.length > 0
          ? allergiesList
          : renderItemNone(hasAllergiesLabel)}
      </div>
    );
  };

  const renderCurrentMedication = () => {
    const currentMedicationList = patient.takingMedicationDetails.map((el) => {
      return renderItemChecked(el);
    });

    return (
      <div className="appointment-view-sidebar-patient-medical">
        <div className="appointment-view-sidebar-patient-medical__title">
          Current Medication
        </div>
        {currentMedicationList.length > 0
          ? currentMedicationList
          : renderItemNone()}
      </div>
    );
  };

  const renderHrt = () => {
    const hrtList = patient.takingHrtDetails.map((el) => {
      const item = getItemById(hrtItems, el);
      return renderItemChecked(item.name);
    });

    if (patient.takingHrtOther && patient.takingHrtOther.length > 0) {
      hrtList.push(renderItemChecked(patient.takingHrtOther));
    }

    let previousHrtList = null;
    if (patient.previousHrtDetails) {
      previousHrtList = patient.previousHrtDetails.map((el) => {
        const item = getItemById(hrtItems, el);
        return renderItemChecked(item.name);
      });

      if (patient.previousHrtOther && patient.previousHrtOther.length > 0) {
        previousHrtList.push(renderItemChecked(patient.previousHrtOther));
      }
    }

    return (
      <>
        <div className="appointment-view-sidebar-patient-medical">
          <div className="appointment-view-sidebar-patient-medical__title">
            Current HRT
          </div>
          {hrtList.length > 0 ? hrtList : renderItemNone()}
        </div>
        {previousHrtList !== null ? (
          <div className="appointment-view-sidebar-patient-medical">
            <div className="appointment-view-sidebar-patient-medical__title">
              Previous HRT
            </div>
            {previousHrtList.length > 0 ? previousHrtList : renderItemNone()}
          </div>
        ) : null}
      </>
    );
  };

  const renderPatientGp = () => {
    const { gp } = patient;
    if (!gp) {
      return (
        <div className="appointment-view-sidebar-patient-medical">
          <div className="appointment-view-sidebar-patient-medical__title">
            Patient GP
          </div>
          {renderItemNone('Not provided')}
        </div>
      );
    }

    const surgeryAddress = [
      gp.surgeryAddress.line1,
      gp.surgeryAddress.line2,
      gp.surgeryAddress.city,
      gp.surgeryAddress.region,
      gp.surgeryAddress.postcode,
    ]
      .filter((el) => el && el.length > 0)
      .join(', ');

    return (
      <>
        <div className="appointment-view-sidebar-patient-medical">
          <div className="appointment-view-sidebar-patient-medical__title">
            Patient GP
          </div>
          <p>{gp.gpName || '[GP NAME NOT PROVIDED]'},</p>
          <p>{gp.surgeryName},</p>
          <p>{surgeryAddress}</p>
        </div>

        {gp.emergencyContact && Object.keys(gp.emergencyContact).length && (
          <div className="appointment-view-sidebar-patient-medical">
            <div className="appointment-view-sidebar-patient-medical__title">
              Emergency Contact
            </div>
            <p>
              {gp.emergencyContact.name ||
                '[EMERGENCY CONTACT NAME NOT PROVIDED]'}
            </p>
            <p>{gp.emergencyContact.relationship}</p>
            <p>{gp.emergencyContact.tel}</p>
          </div>
        )}

        <div className="appointment-view-sidebar-patient-medical">
          <div className="appointment-view-sidebar-patient-medical__title">
            Consent to share with patient GP
          </div>
          {gp.confirmSharePatientOwnGpAt !== null
            ? renderItemChecked('Consent given')
            : renderItemNone('Consent NOT given')}
        </div>
      </>
    );
  };

  const renderPatientDetails = () => {
    const patientFullname = [
      patient.firstName,
      patient.middleName,
      patient.lastName,
    ].join(' ');

    const patientDob = dayjs(patient.dob).format('DD/MM/YYYY');
    const patientAge = dayjs().diff(dayjs(patient.dob), 'year');

    const weightInPounds = patient.weightStone * 14 + patient.weightPounds;
    const heightInInches = patient.heightFeet * 12 + patient.heightInches;
    const bmi = (weightInPounds / (heightInInches * heightInInches)) * 703;

    const patientAddress = [
      patient.address.line1,
      patient.address.line2,
      patient.address.city,
      patient.address.region,
      patient.address.postcode,
    ]
      .filter((el) => el && el.length > 0)
      .join(', ');
    return (
      <>
        <div className="appointment-view-sidebar-patient-medical--patient-details">
          <div className="appointment-view-sidebar-patient__name">
            {patientFullname}
          </div>
          <div className="appointment-view-sidebar-patient__email">
            {patient.email}
          </div>
          <div>Tel: {patient.phoneNumber}</div>
          {patient.number && <div>PID: HH-{patient.number}</div>}
          {renderPatientAppointments()}
          <div className="appointment-view-sidebar-patient-medical__title">
            Patient Details
          </div>
          <div className="appointment-view-sidebar-patient__dob">
            DOB: {patientDob} (Age: {patientAge})
          </div>
          {showExtendedPatientDetails && (
            <>
              <div className="appointment-view-sidebar-patient__height-weight">
                <div className="appointment-view-sidebar-patient__height">
                  Height: {patient.heightFeet}ft {patient.heightInches}in
                </div>
                <div className="appointment-view-sidebar-patient__weight">
                  Weight: {patient.weightStone}st {patient.weightPounds}lb
                </div>
                <div className="appointment-view-sidebar-patient__bmi">
                  BMI: {bmi.toFixed(1)}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="appointment-view-sidebar-patient-medical">
          <div className="appointment-view-sidebar-patient-medical__title" />
          Address: {patientAddress}
        </div>
        {renderPatientGp()}
        <div className="appointment-view-sidebar-patient-medical">
          <div className="appointment-view-sidebar-patient-medical__title">
            {showExtendedPatientDetails
              ? 'Impact of menopause symptoms'
              : 'Patient Notes'}
          </div>
          {appointment.patientPreAppointmentNotes
            ? appointment.patientPreAppointmentNotes
            : '[None]'}
        </div>

        {showExtendedPatientDetails && (
          <div className="appointment-view-sidebar-patient-medical">
            <div className="appointment-view-sidebar-patient-medical__title">
              Patient questions for GP
            </div>
            {appointment.patientPreAppointmentQuestions
              ? appointment.patientPreAppointmentQuestions
              : '[None]'}
          </div>
        )}

        {showExtendedPatientDetails && (
          <div className="appointment-view-sidebar-patient-medical">
            <div className="appointment-view-sidebar-patient-medical__title">
              Reason for appointment
            </div>
            {appointment.patientPreAppointmentReason
              ? appointment.patientPreAppointmentReason
              : '[None]'}
          </div>
        )}

        {renderLifestyle()}
        {renderMenstrualCycle()}
        {renderSmearTest()}
        {renderMenopauseSymptoms()}
        {renderMedicalProcedures()}
        {renderMedicalHistory()}
        {renderContraception()}
        {renderAllergies()}
        {renderCurrentMedication()}
        {renderHrt()}
      </>
    );
  };

  return renderPatientDetails();
};

export default Sidebar;
