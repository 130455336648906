import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import Amplify from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { Provider } from 'react-redux';
import { StylesProvider } from '@material-ui/core/styles';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import axios from 'axios';
import AppRouter from './routers/AppRouter.js';
import store from './app/store.js';
import * as serviceWorker from './serviceWorker.js';
import packageInfo from '../package.json';

import { awsconfig } from './config/aws-config.js';

// const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

Amplify.configure(awsconfig);

Bugsnag.start({
  apiKey: '77c5a244a10aa37d27fda149a43690b5',
  appVersion: packageInfo.version,
  releaseStage: process.env.REACt_APP_STAGE || 'staging',
  plugins: [new BugsnagPluginReact()],
});

axios.interceptors.request.use(
  async (config) => {
    // If we don't have a user, then don't care...
    const user = await Auth.currentUserInfo();
    console.log('user', user);
    if (!user) return config;

    const session = await Auth.currentSession();
    try {
      const token = session.getAccessToken().getJwtToken();
      console.log(token);

      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.authorization = token;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    return config;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* Inject the material UI styles first into the head so they can
      be overridden by component styles
      https://material-ui.com/styles/advanced/#css-injection-order */}
      <StylesProvider injectFirst>
        <AppRouter />
      </StylesProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
