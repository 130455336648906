/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Button from '../elements/Button.js';
import AlertConfirm from '../elements/alert/Confirm.js';
import AlertError from '../elements/alert/Error.js';
import {
  AppointmentStatuses,
  replaceAppointment,
} from '../../slices/appointmentsSlice.js';
import { patch } from '../../services/api/apiAppointment.js';

const WaitingForPatient = (props) => {
  const { appointment } = props;
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState();
  const [showPatientNoShowConfirm, setShowPatientNoShowConfirm] =
    useState(false);

  const patchRequest = async (data) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await patch(appointment._id, data);
      dispatch(replaceAppointment(response.appointment));
    } catch (err) {
      setApiError(
        'There was an error updating the appointment. Please try again.'
      );
    }
  };

  const onClickPatientNoShow = () => {
    setShowPatientNoShowConfirm(true);
  };

  const onPatientNoShowConfirmNo = () => {
    setShowPatientNoShowConfirm(false);
  };

  const onPatientNoShowConfirmYes = async () => {
    const attributesToUpdate = {
      status: AppointmentStatuses.PATIENT_NO_SHOW,
    };

    patchRequest({ attributesToUpdate });
  };

  const onClickPatientArrived = () => {
    const attributesToUpdate = {
      status: AppointmentStatuses.IN_PROGRESS,
    };

    patchRequest({ attributesToUpdate });
  };

  const onCloseAlertError = () => {
    setApiError(null);
  };

  return (
    <>
      <div className="appointment-view__banner">
        Has the patient arrived at the appointment?
        <div className="appointment-view__banner-actions">
          <Button
            color="danger-outline"
            onClick={onClickPatientNoShow}
            className="appointment-view__banner-action"
          >
            Patient No Show
          </Button>
          <Button
            color="primary"
            onClick={onClickPatientArrived}
            className="appointment-view__banner-action"
          >
            Yes
          </Button>
        </div>
      </div>

      {apiError && <AlertError label={apiError} onClose={onCloseAlertError} />}

      {showPatientNoShowConfirm && (
        <AlertConfirm
          title="Confirm Patient No Show"
          label="Are you sure you want to update this appointment with patient no show? Once updated, you will not be able to access this appointment."
          onNo={onPatientNoShowConfirmNo}
          onYes={onPatientNoShowConfirmYes}
        />
      )}
    </>
  );
};

WaitingForPatient.propTypes = {
  appointment: PropTypes.object.isRequired,
};

export default WaitingForPatient;
