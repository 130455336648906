import { createSlice } from '@reduxjs/toolkit';
import { getAccount } from '../services/api/apiPractitioner.js';
import { replaceMedicalHistories } from './medicalHistoriesSlice.js';
import { replaceMenopauseSymptoms } from './menopauseSymptomsSlice.js';
import { replaceMedicalProcedures } from './medicalProceduresSlice.js';
import { replaceContraceptions } from './contraceptionsSlice.js';
import { replaceHrts } from './hrtsSlice.js';
import { replaceAppointments } from './appointmentsSlice.js';
import { replacePatients } from './patientsSlice.js';
import { replacePractitioners } from './practitionersSlice.js';

export const practitionerSlice = createSlice({
  name: 'practitioner',
  initialState: {
    fetched: false,
    isAuthenticated: false,
    entity: null,
  },
  // Redux Toolkit allows us to write "mutating" logic in reducers. It
  // doesn't actually mutate the state because it uses the Immer library,
  // which detects changes to a "draft state" and produces a brand new
  // immutable state based off those changes
  reducers: {
    practitionerLoading(state) {
      state.fetched = false;
      state.entity = null;
    },
    practitionerReceived(state, action) {
      state.fetched = true;
      state.isAuthenticated = true;
      state.entity = action.payload;
    },
    practitionerNotAuthenticated(state) {
      state.fetched = true;
      state.isAuthenticated = false;
      state.entity = null;
    },
    replacePractitioner(state, action) {
      state.entity = action.payload;
    },
  },
});

export const {
  practitionerLoading,
  practitionerReceived,
  practitionerNotAuthenticated,
  replacePractitioner,
} = practitionerSlice.actions;

export const fetchPractitioner = () => async (dispatch) => {
  dispatch(practitionerLoading());

  try {
    const {
      medicalHistories,
      menopauseSymptoms,
      medicalProcedures,
      contraceptions,
      hrts,
      hrtCategories,
      practitioner,
      practitioners,
      appointments,
      patients,
    } = await getAccount();
    dispatch(replaceMedicalHistories(medicalHistories));
    dispatch(replaceMenopauseSymptoms(menopauseSymptoms));
    dispatch(replaceMedicalProcedures(medicalProcedures));
    dispatch(replaceContraceptions(contraceptions));
    dispatch(replaceHrts({ hrtCategories, hrts }));

    dispatch(replacePatients(patients));
    dispatch(replaceAppointments(appointments));
    dispatch(replacePractitioners(practitioners));
    dispatch(practitionerReceived(practitioner));
  } catch (err) {
    dispatch(practitionerNotAuthenticated());
  }
};

// Selectors ******************************************************************
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export const selectPractitioner = (state) => {
  return state.practitioner.entity;
};

export const selectPractitionerFetched = (state) => {
  return state.practitioner.fetched;
};

export const selectPractitionerIsAuthenticated = (state) => {
  return state.practitioner.fetched && state.practitioner.isAuthenticated;
};

export default practitionerSlice.reducer;
