/* eslint-disable import/prefer-default-export */
export const awsconfig = {
  Auth: {
    region: process.env.REACT_APP_COG_REGION || 'eu-west-2',
    userPoolId: process.env.REACT_APP_COG_USER_POOL || 'eu-west-2_AdCKyM955',
    userPoolWebClientId:
      process.env.REACT_APP_COG_CLIENT_ID || '49uh2nqoga3gbi96h8p9srro4o',
    mandatorySignIn: false,
    authenticationFlowType: 'CUSTOM_AUTH',
    storage: sessionStorage,
  },
};
