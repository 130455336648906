import axios from 'axios';

class ApiError extends Error {
  constructor(statusCode, message, responseData) {
    super(message);
    this.responseData = responseData;
  }
}

const get = (url) => {
  return axios.get(url).then((resp) => resp.data);
};

const post = (url, data) => {
  return axios.post(url, data).then((resp) => resp.data);
};
const patch = (url, data) => {
  return axios.patch(url, data).then((resp) => resp.data);
};

const request = async (url, method, body = null) => {
  let response = null;
  switch (method) {
    case 'GET':
      response = await get(url);
      break;
    case 'POST':
      response = await post(url, body);
      break;
    case 'PATCH':
      response = await patch(url, body);
      break;
    default:
      console.log('Invalid method: ', method);
      response = null;
      throw new ApiError(response.status, response.statusText, response);
  }

  return response;
};

export const jsonGet = (url) => {
  return request(url, 'GET');
};

export const jsonPost = (url, body) => {
  return request(url, 'POST', body);
};

export const jsonPut = (url, body) => {
  return request(url, 'PUT', body);
};

export const jsonPatch = (url, body) => {
  return request(url, 'PATCH', body);
};

export const baseApiUrl =
  process.env.REACT_APP_CLINIC_API ||
  'https://api.clinic-dev.healthandher.com/practitioner';
