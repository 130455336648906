import './TextBody.scss';
import React from 'react';
import PropTypes from 'prop-types';

const TextBody = (props) => {
  const { type, emphasis, small, className, children, ...rest } = props;

  const classes = ['text-body'];
  classes.push(`text-body--${type}`);

  if (emphasis) {
    classes.push('text-body--emphasis');
  }

  if (small) {
    classes.push('text-body--small');
  }

  if (className.length > 0) {
    classes.push(className);
  }

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      className={classes.join(' ')}
    >
      {children}
    </div>
  );
};

TextBody.propTypes = {
  type: PropTypes.oneOf(['default']),
  className: PropTypes.string,
  emphasis: PropTypes.bool,
  small: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

TextBody.defaultProps = {
  type: 'default',
  className: '',
  emphasis: false,
  small: false,
};

export default TextBody;
