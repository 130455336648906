import './Content.scss';
import React from 'react';
import PropTypes from 'prop-types';

const Content = (props) => {
  const { className, children } = props;

  const classes = ['content'];

  if (className.length > 0) {
    classes.push(className);
  }

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={classes.join(' ')}
    >
      {children}
    </div>
  );
};

Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Content.defaultProps = {
  className: '',
};

export default Content;
