import './History.scss';
import '../elements/form/Select.scss';
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import localeEs from 'dayjs/locale/es';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useSelector } from 'react-redux';
import PageHeader from '../elements/PageHeader.js';
import TextTitle from '../elements/TextTitle.js';
import Appointment from '../home/Appointment.js';
import { getHistoryForPractitioner } from '../../services/api/apiAppointment.js';
import { selectPractitioner } from '../../slices/practitionerSlice.js';
import { AppointmentStatuses } from '../../slices/appointmentsSlice.js';
import AlertError from '../elements/alert/Error.js';
import { selectAllPractitioners } from '../../slices/practitionersSlice.js';
import LoadingSpinner from '../elements/LoadingSpinner.js';

dayjs.extend(relativeTime);

const History = () => {
  const practitioner = useSelector(selectPractitioner);
  const practitioners = useSelector(selectAllPractitioners);
  const [appointments, setAppointments] = useState([]);
  const [patients, setPatients] = useState([]);
  const [practitionerId, setPractitionerId] = useState(practitioner._id);
  const [apiError, setApiError] = useState();
  const [loading, setLoading] = useState(false);
  const [patientSearch, setPatientSearch] = useState('');
  const history = useHistory();

  const getAppointmentsRequest = async () => {
    setLoading(true);
    try {
      const response = await getHistoryForPractitioner(practitionerId);
      setAppointments(response.appointments);
      setPatients(response.patients);
    } catch (err) {
      setApiError(
        'There was an error loading the appointments. Please try again.'
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getAppointmentsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practitionerId]);

  const onCloseAlertError = () => {
    history.push('/practitioner/P4hR9rfXEVpoprOs6m0pJR9LultTHbO1');
  };

  const onChangePractitionerDropdown = (e) => {
    setPatientSearch('');
    const selectedPractitionerId = e.target.value;
    setPractitionerId(selectedPractitionerId);
  };

  const renderPractitionerDropdown = () => {
    const options = [
      ...practitioners.map((el) => {
        const practitionerFullname = [el.title, el.firstName, el.lastName].join(
          ' '
        );

        const selected = el._id === practitioner._id;
        return (
          <option key={el._id} value={el._id} selected={selected}>
            {practitionerFullname}
          </option>
        );
      }),
    ];
    return (
      <div className="past-appointments__practitioner">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="form-text-input__label" htmlFor="practitionerId">
          Practitioner
        </label>
        <select
          id="practitionerId"
          className="past-appointments__select-practitioner field-select"
          name="practitioner"
          onChange={onChangePractitionerDropdown}
        >
          {options}
        </select>
      </div>
    );
  };

  const renderLoadSpinnerRow = () => (
    <tr>
      <td colSpan="5" className="upcoming-appointment">
        <LoadingSpinner />
      </td>
    </tr>
  );

  const renderPastAppointments = () => {
    let pastAppointmentsContent;

    const pastAppointments = appointments
      ? appointments
          .filter((el) => {
            const statuses = [AppointmentStatuses.COMPLETED];
            return statuses.includes(el.status);
          })
          .sort((a, b) => (dayjs(a.startAt).isBefore(b.startAt) ? 1 : -1))
      : [];

    if (pastAppointments.length === 0) {
      pastAppointmentsContent = (
        <tr>
          <td colSpan="5" className="upcoming-appointment">
            There are no appointments.
          </td>
        </tr>
      );
    } else {
      let resultsOfSearch = 0;
      pastAppointmentsContent = pastAppointments.map((appointment) => {
        const patient = patients.find(
          (patientEl) => patientEl._id === appointment.patientId
        );

        const patientNameToMatch = [patient.firstName, patient.lastName]
          .join(' ')
          .toLowerCase();
        if (
          patientSearch &&
          !patientNameToMatch.includes(patientSearch.toLowerCase())
        )
          return null;
        resultsOfSearch += 1;
        return (
          <Appointment
            key={appointment._id}
            appointment={appointment}
            patient={patient}
            view="table"
            showPractitioner
          />
        );
      });
      if (resultsOfSearch === 0 && patientSearch)
        return (
          <tr className="upcoming-appointment">
            <td colSpan={5}>
              No results found for patient: <strong>{patientSearch}</strong>
            </td>
          </tr>
        );
    }
    return pastAppointmentsContent;
  };

  return (
    <>
      <PageHeader />
      <div className="past-appointments">
        <div className="past-appointments__list">
          <div className="past-appointments__header">
            <TextTitle>Past Appointments</TextTitle>
            {renderPractitionerDropdown()}
          </div>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="form-text-input__label" htmlFor="filter-patient">
            Patient
          </label>
          <input
            type="text"
            id="filter-patient"
            name="filter-patient"
            className="past-appointments__search-patient past-appointments__input"
            value={patientSearch}
            onChange={(event) => {
              setPatientSearch(event.target.value);
            }}
          />
        </div>
        <table className="past-appointments__table">
          <thead>
            <tr className="upcoming-appointment">
              <th>Patient Name</th>
              <th>Appointment Date</th>
              <th>Status</th>
              <th>Practitioner</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loading ? renderLoadSpinnerRow() : renderPastAppointments()}
          </tbody>
        </table>
        {apiError && (
          <AlertError label={apiError} onClose={onCloseAlertError} />
        )}
      </div>
    </>
  );
};

export default History;
