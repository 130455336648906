// using convertFromHTML from draft-convert rather than draft-js as it seems
// to handle <br> tags better
import { convertFromHTML } from 'draft-convert';
import dayjs from 'dayjs';
// eslint-disable-next-line no-unused-vars
import advancedFormat from 'dayjs/plugin/advancedFormat';

export const defaultGpLetterText = `I would like to introduce myself, [PRACTITIONER_NAME], as a practising GP with a specialist interest in Women’s Health. I provide advice and guidance to support Health & Her - an online company who offer advice to women around their menopausal concerns. Your patient, [PATIENT_NAME], has contacted us today and we have had a 35-minute private remote consultation. 

The symptoms and concerns that we discussed today are outlined below (as taken directly from our patient notes): 

[INSERT_HERE]

Based on this information and any subsequent discussion, my final thoughts and recommendations are: 

[INSERT_RECOMMENDATION_1]
[INSERT_RECOMMENDATION_2]
[INSERT_RECOMMENDATION_3]

We also of course recommend that a complete review of medical records and a blood pressure test (if this has not yet been done) is completed before any prescription is issued. We have explained this to the patient today.  

We, as GPs, follow the NICE NG23 (Nov 15) and British Menopause Society guidance.  In October 2020, the BMS published this helpful summary for menopausal care which we have attached which you may find helpful. 

We hope that you have found this informative and that your patient has benefited from our advice today. 

Many thanks for your time and help; it is very much appreciated. 

Kindest regards,
[PRACTITIONER_NAME]
`;

export const defaultPatientNotesText = `Appointment date: [APPOINTMENT_DATE_TIME]
Appointment with [PRACTITIONER_NAME]

Patient Name: [PATIENT_FULLNAME]
Address: [PATIENT_ADDRESS]
DOB: [PATIENT_DOB]
Age: [PATIENT_AGE]

<b><u>Our consultation today</u></b>

It was a pleasure speaking to you today in the Health & Her Clinic and thank you for sharing your story. I detail below a summary of what we discussed and my recommendations to help you move forward. Please also review the Health & Her website along with the links provided at the bottom of your notes as you may find these articles useful for you on this life transition. 

You will notice a button above titled 'Review GP Letter'. This is the letter for your GP outlining my recommendations. Please note that we do not send this letter to your GP practice so if you would like to share it with them, please do so by printing or downloading the document. 

<b>Your Symptoms and Concerns (as discussed during our appointment today):</b>
<<<< Doctor to complete >>>>

<b>Our agreed plan and my recommendations:</b>
<<<< Doctor to complete >>>>

Please also:

- Download/print and send the GP letter that I have written for you to your NHS GP (we do not send this) and consider also sharing with them these detailed patient notes</li>

- Have a read through the expert advice section on our website at healthandher.com/expert-advice/</li>

- Track your symptoms using our free menopause app - search for Health & Her Menopause App in Apple App Store or Google Play Store</li>

- Arrange a follow up with either your NHS GP or another Health & Her Menopause GP in 3 months time (or sooner if you experience any problems) to review any changes to your treatment. </li></ul>

I hope this information is helpful and provides you with some benefit for your health and helps achieve some relief from your symptoms. I wish you all the very best for the future.

Kindest Regards,

[PRACTITIONER_NAME]

<b><u>Key Background Information From your Pre appointment Questionnaire/Our Chat today</u></b>

<b>Symptoms you are experiencing</b>

When you completed your brief history you told me you are suffering from the following symptoms: - 

[PATIENT_SYMPTOMS]

<b>Your Medical history</b>

[PATIENT_MEDICAL_HISTORY]

[PATIENT_STATS]


<b><u>WEBSITE LINKS</u></b>

HRT:
https://healthandher.com/expert-advice/all-symptoms/hormone-replacement-therapy-hrt-guide/

https://www.womens-health-concern.org/wp-content/uploads/2020/03/WHC-FACTSHEET-HRT-BenefitsRisks-MAR2020.pdf 

The stages of menopause:
https://healthandher.com/expert-advice/stress-anxiety/menopause-perimenopause-and-post-menopause-a-gps-overview/

Individual symptoms:
https://healthandher.com/expert-advice/expertise/gynaecologist/

https://healthandher.com/expert-advice/vaginal-dryness/menopause-vaginal-changes-explained/

https://healthandher.com/expert-advice/expertise/gp/

Sleep:
https://healthandher.com/expert-advice/sleeping-problems/is-menopause-messing-with-your-sleep-tools-techniques-and-treatment-to-reclaim-your-rest/`;

const getItemById = (list, id) => {
  return list.find((el) => el._id === id);
};

const getSmokingStatusText = (patient) => {
  let smokingLabel = patient.smokingStatus.replace(/_/g, ' ').toLowerCase();
  smokingLabel = smokingLabel.charAt(0).toUpperCase() + smokingLabel.slice(1);

  return `<u>Smoking status:</u> ${smokingLabel}`;
};

const getAlcoholStatusText = (patient) => {
  let alcoholText = `<u>Alcohol status:</u> ${
    patient.alcoholStatus === 'YES' ? 'Drinks alcohol' : "Doesn't drink Alcohol"
  }`;

  if (patient.alcoholStatus === 'YES') {
    alcoholText += `
Units per week: ${patient.alcoholUnitPerWeek}
Alcohol free nights per week: ${patient.alcoholFreeNightsPerWeek}`;
  }

  return alcoholText;
};

const getMenstrualCycleText = (patient) => {
  if (patient.lastPeriodSelectedDate) {
    return `<u>Last period:</u> ${
      patient.lastPeriodRange
    } months ago (entered ${dayjs(patient.lastPeriodSelectedDate).format(
      'd MMMM YYYY'
    )})`;
  }
  return `<u>Last period:</u> ${dayjs(patient.lastPeriodAt).format(
    'MMMM YYYY'
  )}`;
};

const getCervicalSmearTestText = (patient) => {
  let cervicalSmearTestText = `<u>Cervical smear test:</u> ${
    patient.hadSmearTest === 'YES' ? 'Tested' : 'Not tested'
  }`;

  if (patient.hadSmearTest === 'YES') {
    cervicalSmearTestText += `
Last smear test: ${dayjs(patient.lastSmearTestAt).format('MMMM YYYY')}`;
  }

  return cervicalSmearTestText;
};

const getMenopauseSymptomsText = (patient, menopauseSymptoms) => {
  const menopauseSymptomsList = patient.menopauseSymptoms.map((el) => {
    if (el === 'NONE') {
      return 'None';
    }
    const item = getItemById(menopauseSymptoms, el);
    return item.name;
  });

  let menopauseSymptomsText = '<u>Menopause symptoms:</u> ';

  if (menopauseSymptomsList.length > 0) {
    menopauseSymptomsText += `
${menopauseSymptomsList.join('\n')}`;
  } else {
    menopauseSymptomsText += 'None';
  }

  return menopauseSymptomsText;
};

const getMedicalProceduresText = (patient, medicalProcedures) => {
  let label = '';
  let additionalLabel = '';
  let medicalProceduresText = '<u>Medical procedures:</u>';
  if (patient.medicalProcedure && !patient.medicalProcedures) {
    // medicalProcedure deprecated in favour of medicalProcedures. medicalProcedure kept here for previous entries before medicalProcedures was introduced
    if (patient.medicalProcedure === 'NONE') {
      label = 'None';
    } else if (patient.medicalProcedure === 'OTHER') {
      label = `
${patient.medicalProcedureOther}`;
    } else {
      const item = getItemById(medicalProcedures, patient.medicalProcedure);
      label = `
${item.name}`;
      additionalLabel = patient.medicalProcedureExtra;
    }

    medicalProceduresText += ` ${label}`;

    if (additionalLabel.length > 0) {
      medicalProceduresText += `
  ${additionalLabel}`;
    }
  } else if (patient.medicalProcedures && patient.medicalProcedures.length) {
    patient.medicalProcedures.forEach((medicalProcedure) => {
      const item = getItemById(medicalProcedures, medicalProcedure);
      medicalProceduresText += `
${item.name}`;
      if (
        item.provideDetails &&
        patient.medicalProcedureDetails &&
        patient.medicalProcedureDetails[item._id]
      ) {
        medicalProceduresText += `
Why was this done and when: ${patient.medicalProcedureDetails[item._id]}`;
      }
    });
  } else {
    medicalProceduresText += ' None';
  }

  return medicalProceduresText;
};

const getMedicalHistoriesText = (patient, medicalHistories) => {
  const medicalHistoryList = patient.medicalHistory.map((el) => {
    if (el === 'NONE') {
      return 'None';
    }
    const item = getItemById(medicalHistories, el);
    return item.name;
  });

  if (patient.medicalHistoryOther && patient.medicalHistoryOther.length > 0) {
    medicalHistoryList.push(patient.medicalHistoryOther);
  }

  let medicalHistoryText = '<u>Medical history:</u> ';

  if (medicalHistoryList.length > 0) {
    medicalHistoryText += `
${medicalHistoryList.join('\n')}`;
  } else {
    medicalHistoryText += 'None';
  }

  return medicalHistoryText;
};

const getContraceptionsText = (patient, contraceptions) => {
  const contraceptionList = patient.takingContraceptionDetails.map((el) => {
    const item = getItemById(contraceptions, el);
    return item.name;
  });

  if (
    patient.takingContraceptionOther &&
    patient.takingContraceptionOther.length > 0
  ) {
    contraceptionList.push(patient.takingContraceptionOther);
  }

  let contraceptionText = '<u>Contraception:</u> ';

  if (contraceptionList.length > 0) {
    contraceptionText += `
${contraceptionList.join('\n')}`;
  } else {
    contraceptionText += 'None';
  }

  return contraceptionText;
};

const getAllergiesText = (patient) => {
  const allergiesList = patient.hasAllergiesDetails.map((el) => {
    return el;
  });

  let allergiesText = '<u>Allergies:</u> ';

  if (allergiesList.length > 0) {
    allergiesText += `
${allergiesList.join('\n')}`;
  } else {
    let hasAllergiesLabel = 'No';
    if (patient.hasAllergies === 'DONT_KNOW') {
      hasAllergiesLabel = 'Dont Know';
    }
    allergiesText += hasAllergiesLabel;
  }

  return allergiesText;
};

const getCurrentMedicationsText = (patient) => {
  const currentMedicationList = patient.takingMedicationDetails.map((el) => {
    return el;
  });

  let medicationText = '<u>Medication:</u> ';

  if (currentMedicationList.length > 0) {
    medicationText += `
${currentMedicationList.join('\n')}`;
  } else {
    medicationText += 'None';
  }

  return medicationText;
};

const getHrtText = (patient, hrtItems) => {
  const hrtList = patient.takingHrtDetails.map((el) => {
    const item = getItemById(hrtItems, el);
    return item.name;
  });

  if (patient.takingHrtOther && patient.takingHrtOther.length > 0) {
    hrtList.push(patient.takingHrtOther);
  }

  let currentHrtText = '<u>Current HRT: </u> ';

  if (hrtList.length > 0) {
    currentHrtText += `
${hrtList.join('\n')}`;
  } else {
    currentHrtText += 'None';
  }

  return currentHrtText;
};

const getPreviousHrtText = (patient, hrtItems) => {
  let previousHrtList = [];
  if (patient.previousHrtDetails) {
    previousHrtList = patient.previousHrtDetails.map((el) => {
      const item = getItemById(hrtItems, el);
      return item.name;
    });

    if (patient.previousHrtOther && patient.previousHrtOther.length > 0) {
      previousHrtList.push(patient.previousHrtOther);
    }
  }

  let previousHrtText = '<u>Previous HRT: </u> ';

  if (previousHrtList.length > 0) {
    previousHrtText += `
${previousHrtList.join('\n')}`;
  } else {
    previousHrtText += 'None';
  }

  return previousHrtText;
};
const getShowExtendedPatientDetails = (patient) => {
  // During April 2021, the patient appointment booking process was extended significantly
  // to include a lot more questions. If the patient has entered information for the attribute
  // below then we should show answers for all the extra questions
  return patient.heightFeet !== undefined;
};

const getPatientMedicalHistoryText = ({
  patient,
  medicalHistories,
  menopauseSymptoms,
  medicalProcedures,
  contraceptions,
  hrtItems,
}) => {
  const textParts = [];

  if (getShowExtendedPatientDetails(patient)) {
    textParts.push(getSmokingStatusText(patient));
    textParts.push(getAlcoholStatusText(patient));
    textParts.push(getMenstrualCycleText(patient));
    textParts.push(getCervicalSmearTestText(patient));
    textParts.push(getMenopauseSymptomsText(patient, menopauseSymptoms));
    textParts.push(getMedicalProceduresText(patient, medicalProcedures));
  }

  textParts.push(getMedicalHistoriesText(patient, medicalHistories));
  textParts.push(getContraceptionsText(patient, contraceptions));
  textParts.push(getAllergiesText(patient));
  textParts.push(getCurrentMedicationsText(patient));
  textParts.push(getHrtText(patient, hrtItems));

  if (getShowExtendedPatientDetails(patient)) {
    textParts.push(getPreviousHrtText(patient, hrtItems));
  }
  return textParts.join('\n\n');
};

const getPractitionerName = (practitioner) => {
  return [
    practitioner.title,
    practitioner.firstName,
    practitioner.lastName,
  ].join(' ');
};

const getPatientName = (patient) => {
  return [patient.firstName, patient.middleName, patient.lastName]
    .filter((el) => el && el.length > 0)
    .join(' ');
};

const getPatientAge = (dob) => {
  const birthDate = new Date(dob);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
};

const formatPatientAddress = (address) => {
  let patientAddress = address.line1;
  if (address.line2) patientAddress += `, ${address.line2}`;
  patientAddress += `
${address.city}`;
  patientAddress += `, ${address.region}`;
  patientAddress += ` 
${address.postcode}`;
  return patientAddress;
};

export const getPopulatedDefaultPatientNotesText = ({
  practitioner,
  patient,
  appointment,
  medicalHistories,
  menopauseSymptoms,
  medicalProcedures,
  contraceptions,
  hrtItems,
}) => {
  let updatedPatientNotesText = defaultPatientNotesText;

  updatedPatientNotesText = updatedPatientNotesText.replace(
    /\[PATIENT_FULLNAME\]/gi,
    getPatientName(patient)
  );

  updatedPatientNotesText = updatedPatientNotesText.replace(
    /\[PATIENT_DOB\]/gi,
    dayjs(patient.dob).format('DD/MM/YYYY')
  );

  updatedPatientNotesText = updatedPatientNotesText.replace(
    /\[PATIENT_AGE\]/gi,
    getPatientAge(patient.dob)
  );

  updatedPatientNotesText = updatedPatientNotesText.replace(
    /\[PATIENT_ADDRESS\]/gi,
    formatPatientAddress(patient.address)
  );

  const weightInPounds = patient.weightStone * 14 + patient.weightPounds;
  const heightInInches = patient.heightFeet * 12 + patient.heightInches;
  const bmi = (weightInPounds / (heightInInches * heightInInches)) * 703;

  let patientStats = '';
  if (getShowExtendedPatientDetails(patient)) {
    const patientHeight = `Height: ${patient.heightFeet}ft ${patient.heightInches}in`;
    const patientWeight = `Weight: ${patient.weightStone}st ${patient.weightPounds}lb`;
    const patientBmi = `BMI: ${bmi.toFixed(1)}`;

    patientStats = `${patientHeight}, ${patientWeight}, ${patientBmi}`;
  }

  updatedPatientNotesText = updatedPatientNotesText.replace(
    /\[PATIENT_STATS\]/gi,
    patientStats
  );

  updatedPatientNotesText = updatedPatientNotesText.replace(
    /\[APPOINTMENT_DATE_TIME\]/gi,
    dayjs(appointment.startAt).format('dddd Do MMMM YYYY [at] h.mma')
  );

  updatedPatientNotesText = updatedPatientNotesText.replaceAll(
    /\[PRACTITIONER_NAME\]/gi,
    getPractitionerName(practitioner)
  );

  updatedPatientNotesText = updatedPatientNotesText.replace(
    /\[PATIENT_SYMPTOMS\]/gi,
    appointment.patientPreAppointmentNotes
  );

  updatedPatientNotesText = updatedPatientNotesText.replace(
    /\[PATIENT_MEDICAL_HISTORY\]/gi,
    getPatientMedicalHistoryText({
      practitioner,
      patient,
      medicalHistories,
      menopauseSymptoms,
      medicalProcedures,
      contraceptions,
      hrtItems,
    })
  );

  // Replace newlines with HTML <br> tags - just so we dont have to clutter
  // the default text
  updatedPatientNotesText = updatedPatientNotesText.replace(/\n/g, '<br>');

  return convertFromHTML(updatedPatientNotesText);
};

export const getPopulatedDefaultGpLetterText = ({ practitioner, patient }) => {
  let updatedPatientNotesText = defaultGpLetterText;

  updatedPatientNotesText = updatedPatientNotesText.replace(
    /\[PRACTITIONER_NAME\]/gi,
    getPractitionerName(practitioner)
  );

  updatedPatientNotesText = updatedPatientNotesText.replace(
    /\[PATIENT_NAME\]/gi,
    getPatientName(patient)
  );

  return updatedPatientNotesText;
};
