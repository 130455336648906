import './ListItem.scss';
import React from 'react';
import PropTypes from 'prop-types';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const ListItem = (props) => {
  const { className, label, onClick } = props;

  const classes = ['menu-list__item'];

  if (className.length > 0) {
    classes.push(className);
  }

  return (
    <div className={classes.join(' ')} onClick={onClick}>
      <div className="menu-list__label">{label}</div>
      <ArrowForwardIosIcon className="menu-list__icon" />
    </div>
  );
};

ListItem.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

ListItem.defaultProps = {
  className: '',
  onClick: null,
};

export default ListItem;
