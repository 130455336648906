import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Auth } from '@aws-amplify/auth';
import TextTitle from '../../elements/TextTitle.js';
import TextBody from '../../elements/TextBody.js';
import Button from '../../elements/Button.js';
import TextInput from '../../elements/form/TextInput.js';
import FormError from '../../elements/form/FormError.js';
import LoadingSpinner from '../../elements/LoadingSpinner.js';
import FormPasswordAndConfirm from '../../forms/PasswordAndConfirm.js';

const ForgottenPasswordChange = (props) => {
  const { onStageComplete, email } = props;
  const { register, handleSubmit, watch, trigger, getValues, errors } = useForm(
    {
      defaultValues: {
        email: 'victoria@healthandher.com',
      },
    }
  );
  const [globalFormError, setGlobalFormError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const forgottenPasswordChangeRequest = async (
    passwordResetCode,
    newPassword
  ) => {
    setRequestInProgress(true);
    try {
      await Auth.forgotPasswordSubmit(email, passwordResetCode, newPassword);
      onStageComplete();
    } catch (err) {
      setGlobalFormError(
        <p>
          Incorrect password reset code, please try again. If the problem
          continues please email clinic@healthandher.com
        </p>
      );
      window.scrollTo(0, 0);

      setRequestInProgress(false);
    }
  };

  const onSubmitForm = (data) => {
    if (requestInProgress) {
      return;
    }

    const { passwordResetCode, newPassword } = data;
    setGlobalFormError(null);
    forgottenPasswordChangeRequest(passwordResetCode, newPassword);
  };

  return (
    <>
      <TextTitle>Forgotten Password</TextTitle>

      <TextBody>
        If the email address you provided is registered for an account, you will
        be sent an email containing a password reset code.
      </TextBody>

      <form
        className="forgotten-password-form"
        onSubmit={handleSubmit(onSubmitForm)}
      >
        {globalFormError !== null && <FormError>{globalFormError}</FormError>}

        <TextInput
          label="Code (sent in email)"
          type="text"
          name="passwordResetCode"
          autoCorrect="off"
          autoCapitalize="off"
          autocomplete="off"
          error={errors.passwordResetCode}
          registerRef={register({
            required: 'This field is required',
            maxLength: {
              value: 10,
              message: 'Too many characters (max 10)',
            },
          })}
        />

        <FormPasswordAndConfirm
          errors={errors}
          register={register}
          watch={watch}
          labels={['Set Password', 'Confirm Password']}
          trigger={trigger}
          getValues={getValues}
        />

        {requestInProgress === true ? (
          <LoadingSpinner />
        ) : (
          <Button type="submit">Update Password</Button>
        )}
      </form>
    </>
  );
};

ForgottenPasswordChange.propTypes = {
  onStageComplete: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
};

export default ForgottenPasswordChange;
