import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectPractitionerIsAuthenticated } from '../slices/practitionerSlice.js';

const { version } = require('../../package.json');

// Wrap a Route with an authentication check and redirect to /login if the
// user isn't authenticated
function AuthenticationRequiredRoute({ component: Component, ...rest }) {
  const userIsAuthenticated = useSelector(selectPractitionerIsAuthenticated);

  const render = (props) => {
    if (userIsAuthenticated === true) {
      return (
        <>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Component {...props} />
          <div className="authRoute__version">
            ClinicPractitioner Version: {version}
          </div>
        </>
      );
    }
    return (
      <Redirect
        to={{
          pathname: '/practitioner/P4hR9rfXEVpoprOs6m0pJR9LultTHbO1/login',
        }}
      />
    );
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...rest} render={render} />;
}

AuthenticationRequiredRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.instanceOf(React.Component),
    PropTypes.func,
  ]).isRequired,
};

export default AuthenticationRequiredRoute;
