/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Button from '../elements/Button.js';
import AlertError from '../elements/alert/Error.js';
import {
  AppointmentStatuses,
  replaceAppointment,
} from '../../slices/appointmentsSlice.js';
import { patch } from '../../services/api/apiAppointment.js';

const InProgress = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { appointment, patient } = props;
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState();

  const patchRequest = async (data) => {
    try {
      const response = await patch(appointment._id, data);
      dispatch(replaceAppointment(response.appointment));
    } catch (err) {
      setApiError(
        'There was an error updating the appointment. Please try again.'
      );
    }
  };

  const onClickAppointmentFinished = () => {
    const attributesToUpdate = {
      status: AppointmentStatuses.WAITING_ON_PRACTITIONER,
    };

    patchRequest({ attributesToUpdate });
  };

  const onCloseAlertError = () => {
    setApiError(null);
  };

  return (
    <>
      <div className="appointment-view__banner">
        <div>
          Has the video consultation with the patient finished?
          <div className="appointment-view__banner-additional-text">
            You should click [Yes] when the video call with the patient has
            finished. Once [Yes] is clicked, you will still be able to edit and
            view your notes below before they are published to the patient.
          </div>
        </div>
        <div className="appointment-view__banner-actions">
          <Button
            color="primary"
            onClick={onClickAppointmentFinished}
            className="appointment-view__banner-action"
          >
            Yes
          </Button>
        </div>
      </div>

      {apiError && <AlertError label={apiError} onClose={onCloseAlertError} />}
    </>
  );
};

InProgress.propTypes = {
  appointment: PropTypes.object.isRequired,
  patient: PropTypes.object.isRequired,
};

export default InProgress;
