import './Confirm.scss';
import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Button from '../Button.js';
import TextTitle from '../TextTitle.js';
import TextBody from '../TextBody.js';

const Confirm = (props) => {
  const { onNo, onYes, title, label } = props;

  return (
    <div className="alert-confirm" onClick={onNo}>
      <div className="alert-confirm__content">
        <div className="alert-confirm__header">
          <TextTitle type="sub" className="alert-confirm__label">
            {title}
          </TextTitle>
          <CloseIcon className="alert-confirm__close" onClick={onNo} />
        </div>
        <TextBody>{label}</TextBody>

        <div className="alert-confirm__actions">
          <Button
            className="alert-confirm__action"
            onClick={onNo}
            color="primary-outline"
          >
            Cancel
          </Button>
          <Button className="alert-confirm__action" onClick={onYes}>
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

Confirm.propTypes = {
  onNo: PropTypes.func.isRequired,
  onYes: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Confirm;
