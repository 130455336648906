import './Account.scss';
import React, { useState } from 'react';
import PageHeader from '../elements/PageHeader.js';
import TextTitle from '../elements/TextTitle.js';
import Content from '../elements/Content.js';
import MenuList from '../elements/menu/List.js';
import MenuListItem from '../elements/menu/ListItem.js';
import Password from './security/Password.js';

const editStage = {
  PASSWORD: 'PASSWORD',
};

const Account = () => {
  const [currentEditStage, setCurrentEditStage] = useState(null);

  const onClickEditPassword = () => {
    setCurrentEditStage(editStage.PASSWORD);
  };

  const onEditStageComplete = () => {
    switch (currentEditStage) {
      case editStage.PASSWORD:
        break;
      default:
        throw new Error(`unexpected stage ${currentEditStage}`);
    }

    setCurrentEditStage(null);
  };

  const onEditStageClose = () => {
    setCurrentEditStage(null);
  };

  const renderEditStage = () => {
    let content;
    switch (currentEditStage) {
      case editStage.PASSWORD:
        content = (
          <Password
            onStageComplete={onEditStageComplete}
            onClickBack={onEditStageClose}
          />
        );
        break;
      default:
        throw new Error(`stage not handled: ${currentEditStage}`);
    }

    window.scrollTo(0, 0);
    return content;
  };

  const renderMainContent = () => {
    return (
      <>
        <PageHeader title="Account" />
        <div className="account">
          <Content>
            <TextTitle type="sub" className="account__title">
              Security
            </TextTitle>
          </Content>

          <MenuList>
            <MenuListItem
              label="Change Password"
              onClick={onClickEditPassword}
            />
          </MenuList>
        </div>
      </>
    );
  };

  const content =
    currentEditStage === null ? renderMainContent() : renderEditStage();

  return <>{content}</>;
};

export default Account;
