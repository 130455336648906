import './TextInput.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import FormFieldError from './FieldError.js';

const TextInput = (props) => {
  const {
    label,
    type,
    name,
    className,
    registerRef,
    error,
    required,
    ...rest
  } = props;

  const id = uuidv4();
  const classes = ['form-text-input'];

  let errorLabel = '';
  if (Object.keys(error).length > 0) {
    classes.push('form-text-input--error');

    if (error.message.length > 0) {
      errorLabel = error.message;
    } else {
      errorLabel = 'Invalid input';
    }
  }

  if (className.length > 0) {
    classes.push(className);
  }

  return (
    <div className={classes.join(' ')}>
      <label className="form-text-input__label" htmlFor={id}>
        {label} {required && '*'}
        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          className="form-text-input__field"
          type={type}
          id={id}
          name={name}
          ref={registerRef}
        />
        {error && errorLabel.length > 0 ? (
          <FormFieldError label={errorLabel} />
        ) : null}
      </label>
    </div>
  );
};

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  registerRef: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  required: PropTypes.bool,
};

TextInput.defaultProps = {
  type: 'text',
  className: '',
  error: {},
  required: false,
};

export default TextInput;
