import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectPractitionerIsAuthenticated } from '../slices/practitionerSlice.js';

// Wrap a Route with an authentication check and redirect an authenticated user
// to home instead of showing an unauthenticated page such as login
function UnauthenticatedRoute({ component: Component, render, ...rest }) {
  const userIsAuthenticated = useSelector(selectPractitionerIsAuthenticated);
  const redirectIfAuthenticated = (props) => {
    if (userIsAuthenticated === true) {
      return (
        <Redirect
          to={{ pathname: '/practitioner/P4hR9rfXEVpoprOs6m0pJR9LultTHbO1' }}
        />
      );
    }

    if (render !== null) {
      return render(props);
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} />;
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...rest} render={redirectIfAuthenticated} />;
}

UnauthenticatedRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.instanceOf(React.Component),
    PropTypes.func,
  ]),
  render: PropTypes.func,
};

UnauthenticatedRoute.defaultProps = {
  component: null,
  render: null,
};

export default UnauthenticatedRoute;
