import './Home.scss';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
// eslint-disable-next-line no-unused-vars
import localeEs from 'dayjs/locale/es';
import relativeTime from 'dayjs/plugin/relativeTime';

import PageHeader from '../elements/PageHeader.js';
import TextTitle from '../elements/TextTitle.js';
import TextBody from '../elements/TextBody.js';
import Content from '../elements/Content.js';
import Appointment from './Appointment.js';
import {
  selectPractitioner,
  fetchPractitioner,
} from '../../slices/practitionerSlice.js';
import {
  selectTodaysAppointments,
  selectUpcomingAppointments,
  selectActionRequiredAppointments,
} from '../../slices/appointmentsSlice.js';
import { selectAllPatients } from '../../slices/patientsSlice.js';

dayjs.extend(relativeTime);

const Home = () => {
  const lastRefresh = new Date().toLocaleDateString('en-GB', {
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  const dispatch = useDispatch();
  const practitioner = useSelector(selectPractitioner);
  const todaysAppointments = useSelector(selectTodaysAppointments);
  const upcomingAppointments = useSelector(selectUpcomingAppointments);
  const actionRequiredAppointments = useSelector(
    selectActionRequiredAppointments
  );
  const patients = useSelector(selectAllPatients);

  const todaysAppointmentsForPractitioner = todaysAppointments.filter(
    (el) => el.practitionerId === practitioner._id
  );

  const upcomingAppointmentsForPractitioner = upcomingAppointments.filter(
    (el) => el.practitionerId === practitioner._id
  );

  const actionRequiredAppointmentsForPractitioner =
    actionRequiredAppointments.filter(
      (el) => el.practitionerId === practitioner._id
    );

  useEffect(() => {
    // 2 mins
    const refreshIntervalMs = 2 * 60000;
    // Refresh the account to bring in any newly booked appointment
    const timeout = window.setTimeout(() => {
      dispatch(fetchPractitioner());
    }, refreshIntervalMs);

    return () => window.clearTimeout(timeout);
  }, [dispatch]);

  const renderTodaysAppointments = () => {
    let todaysAppointmentsContent;

    if (todaysAppointmentsForPractitioner.length === 0) {
      todaysAppointmentsContent = (
        <TextTitle type="sub" className="home__sub-title">
          There are no appointments.
        </TextTitle>
      );
    } else {
      todaysAppointmentsContent = todaysAppointmentsForPractitioner.map(
        (el) => {
          const patient = patients.find(
            (patientEl) => patientEl._id === el.patientId
          );
          return (
            <Appointment
              key={el._id}
              appointment={el}
              patient={patient}
              showPractitioner
            />
          );
        }
      );
    }

    return (
      <div className="home-upcoming-appointments">
        <Content>
          <TextTitle className="mb-10">Today&apos;s Appointments</TextTitle>
          {lastRefresh && <TextBody>Last updated: {lastRefresh}</TextBody>}
          {todaysAppointmentsContent}
        </Content>
      </div>
    );
  };

  const renderActionRequiredAppointments = () => {
    let actionRequiredContent;

    if (actionRequiredAppointmentsForPractitioner.length === 0) {
      actionRequiredContent = (
        <TextTitle type="sub" className="home__sub-title">
          You have no appointments to action.
        </TextTitle>
      );
    } else {
      actionRequiredContent = actionRequiredAppointmentsForPractitioner.map(
        (el) => {
          const patient = patients.find(
            (patientEl) => patientEl._id === el.patientId
          );
          return (
            <Appointment
              key={el._id}
              appointment={el}
              patient={patient}
              showPractitioner
            />
          );
        }
      );
    }

    return (
      <div className="home-upcoming-appointments">
        <Content>
          <TextTitle>Action Required</TextTitle>
          {actionRequiredContent}
        </Content>
      </div>
    );
  };

  const renderUpcomingAppointments = () => {
    let upcomingAppointmentsContent;

    if (upcomingAppointmentsForPractitioner.length === 0) {
      upcomingAppointmentsContent = (
        <TextTitle type="sub" className="home__sub-title">
          There are no upcoming appointments.
        </TextTitle>
      );
    } else {
      upcomingAppointmentsContent = upcomingAppointmentsForPractitioner.map(
        (el) => {
          const patient = patients.find(
            (patientEl) => patientEl._id === el.patientId
          );
          return (
            <Appointment
              key={el._id}
              appointment={el}
              patient={patient}
              showPractitioner
            />
          );
        }
      );
    }

    return (
      <div className="home-upcoming-appointments">
        <Content>
          <TextTitle>Upcoming Appointments</TextTitle>
          {upcomingAppointmentsContent}
        </Content>
      </div>
    );
  };

  const practitionerName = [
    practitioner.title,
    practitioner.firstName,
    practitioner.lastName,
  ].join(' ');

  return (
    <>
      <PageHeader />
      <div className="home">
        <Content>
          <TextTitle className="home__title">Hi {practitionerName},</TextTitle>
        </Content>

        {renderTodaysAppointments()}

        {renderActionRequiredAppointments()}

        {renderUpcomingAppointments()}
      </div>
    </>
  );
};

export default Home;
