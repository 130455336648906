import './View.scss';
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import TextBody from '../elements/TextBody.js';
import PageHeader from '../elements/PageHeader.js';
import Button from '../elements/Button.js';
import {
  AppointmentStatuses,
  replaceAppointment,
  selectAllAppointments,
} from '../../slices/appointmentsSlice.js';
import {
  selectAllPatients,
  replacePatient,
} from '../../slices/patientsSlice.js';
import { get as getAppointment } from '../../services/api/apiAppointment.js';
import AlertError from '../elements/alert/Error.js';
import WaitingForPatient from './WaitingForPatient.js';
import InProgress from './InProgress.js';
import WaitingOnPractitioner from './WaitingOnPractitioner.js';
import Completed from './Completed.js';
import Sidebar from './Sidebar.js';
import { selectAllPractitioners } from '../../slices/practitionersSlice.js';
import { createAuditLog } from '../../services/api/apiAuditLogs.js';
import AppointmentForm from './AppointmentForm.js';

dayjs.extend(advancedFormat);

const View = () => {
  const appointments = useSelector(selectAllAppointments);
  const patients = useSelector(selectAllPatients);
  const { appointmentId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const practitioners = useSelector(selectAllPractitioners);
  const [auditLogCreated, setAuditLogCreated] = useState(false);
  const [relatedAppointments, setRelatedAppointments] = useState([]);
  const [apiError, setApiError] = useState();

  const appointment = appointments.find((el) => el._id === appointmentId);

  const onCloseAlertError = () => {
    history.push('/practitioner/P4hR9rfXEVpoprOs6m0pJR9LultTHbO1');
  };

  const onClickJoinAppointment = () => {};

  const createAuditLogRequest = async () => {
    try {
      await createAuditLog({ appointmentId: appointment._id });
      setAuditLogCreated(true);
    } catch (err) {
      setAuditLogCreated(true);
    }
  };

  const getAppointmentRequest = async () => {
    try {
      const response = await getAppointment(appointmentId);
      dispatch(replaceAppointment(response.appointment));
      dispatch(replacePatient(response.patient));
      setRelatedAppointments(response.relatedAppointments);
    } catch (err) {
      setApiError(
        'There was an error loading the appointment. Please try again.'
      );
    }
  };

  useEffect(() => {
    getAppointmentRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentId]);

  useEffect(() => {
    if (appointment && !auditLogCreated) {
      createAuditLogRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment, auditLogCreated]);

  let patient = null;
  if (appointment) {
    patient = patients.find((el) => el._id === appointment.patientId);
    if (!patient) {
      return (
        <AlertError
          label="Unable to load appointment, the appointment could not be found."
          onClose={onCloseAlertError}
        />
      );
    }
  }

  const renderAppointmentDetails = () => {
    const startAt = dayjs(appointment.startAt);
    const endAt = dayjs(appointment.endAt);

    const attendAnywhereLink =
      'https://consult.attendanywhere.co.uk/waiting-area/view-one/15553';

    const allowJoinAppointment = [
      AppointmentStatuses.BOOKED,
      AppointmentStatuses.IN_PROGRESS,
    ].includes(appointment.status);

    const practitioner = practitioners.find(
      (el) => el._id === appointment.practitionerId
    );

    const practitionerFullname = [
      practitioner.title,
      practitioner.firstName,
      practitioner.lastName,
    ].join(' ');

    return (
      <div className="appointment-view-details">
        <div className="appointment-view-details__date-time">
          <TextBody emphasis>Appointment Details</TextBody>
          <TextBody>Date: {startAt.format('ddd Do MMM')}</TextBody>
          <TextBody>
            Time: {startAt.format('h:mm A')} - {endAt.format('h:mm A')}
          </TextBody>
          <TextBody>Practitioner: {practitionerFullname}</TextBody>
        </div>

        {allowJoinAppointment && (
          <a
            href={attendAnywhereLink}
            target="_blank"
            rel="noreferrer noopener"
            className="appointment-view-details__join-appointment"
          >
            <Button onClick={onClickJoinAppointment}>Join Appointment</Button>{' '}
          </a>
        )}
      </div>
    );
  };

  const renderMainContent = () => {
    if (appointment.status === AppointmentStatuses.BOOKED) {
      return (
        <>
          <WaitingForPatient appointment={appointment} patient={patient} />
          <AppointmentForm appointment={appointment} patient={patient} />
        </>
      );
    }

    if (appointment.status === AppointmentStatuses.IN_PROGRESS) {
      return (
        <>
          <InProgress appointment={appointment} patient={patient} />
          <AppointmentForm appointment={appointment} patient={patient} />
        </>
      );
    }

    if (appointment.status === AppointmentStatuses.WAITING_ON_PRACTITIONER) {
      return (
        <>
          <WaitingOnPractitioner appointment={appointment} patient={patient} />
          <AppointmentForm appointment={appointment} patient={patient} />
        </>
      );
    }

    if (appointment.status === AppointmentStatuses.COMPLETED) {
      return <Completed appointment={appointment} patient={patient} />;
    }

    return <>Appointment Status: {appointment.status}</>;
  };

  return (
    <>
      <PageHeader />
      <div className="appointment-view">
        <div className="appointment-view-left">
          {appointment && patient ? (
            <Sidebar
              appointment={appointment}
              relatedAppointments={relatedAppointments}
              patient={patient}
            />
          ) : (
            ''
          )}
        </div>
        <div className="appointment-view-right">
          {appointment && renderAppointmentDetails()}
          <div className="appointment-view-main-content">
            {appointment ? renderMainContent() : 'Loading'}
          </div>
        </div>
      </div>
      {apiError && <AlertError label={apiError} onClose={onCloseAlertError} />}
    </>
  );
};

export default View;
