import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Auth } from '@aws-amplify/auth';
import TextTitle from '../../elements/TextTitle.js';
import TextBody from '../../elements/TextBody.js';
import Button from '../../elements/Button.js';
import TextInput from '../../elements/form/TextInput.js';
import FormError from '../../elements/form/FormError.js';
import LoadingSpinner from '../../elements/LoadingSpinner.js';

const ForgottenPasswordEmail = (props) => {
  const { onStageComplete } = props;
  const { register, handleSubmit, errors } = useForm({});
  const [globalFormError, setGlobalFormError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const forgottenPasswordInitRequest = async (email) => {
    setRequestInProgress(true);
    try {
      await Auth.forgotPassword(email);
      onStageComplete({ email });
    } catch (err) {
      setGlobalFormError(
        <p>
          Something went wrong, please try again. If the problem continues
          please email{' '}
          <a href="mailto:clinic@healthandher.com">clinic@healthandher.com</a>.
        </p>
      );
      setRequestInProgress(false);
      window.scrollTo(0, 0);
    }
  };

  const onSubmitForm = (data) => {
    if (requestInProgress) {
      return;
    }

    const { email, password } = data;
    setGlobalFormError(null);
    forgottenPasswordInitRequest(email, password);
  };

  return (
    <>
      <TextTitle>Forgotten Password</TextTitle>

      <TextBody>
        Please enter the email address of your practitioner account to request a
        password reset.
      </TextBody>

      <TextBody>
        When your password is changed, you will be logged out from all other
        devices where you were logged in.
      </TextBody>

      <form
        className="forgotten-password-form"
        onSubmit={handleSubmit(onSubmitForm)}
      >
        {globalFormError !== null && <FormError>{globalFormError}</FormError>}

        <TextInput
          label="Email"
          type="text"
          name="email"
          error={errors.email}
          registerRef={register({
            required: 'This field is required',
            maxLength: {
              value: 254,
              message: 'Too many characters (max 254)',
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Must be a valid email',
            },
          })}
        />

        {requestInProgress === true ? (
          <LoadingSpinner />
        ) : (
          <Button type="submit">Request Password Reset</Button>
        )}
      </form>
    </>
  );
};

ForgottenPasswordEmail.propTypes = {
  onStageComplete: PropTypes.func.isRequired,
};

export default ForgottenPasswordEmail;
