/* eslint-disable react/forbid-prop-types */
import './AppointmentForm.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { TextArea, Button, FormError } from '@hh/clinic-app-common';
import AlertError from '../elements/alert/Error.js';
import { replaceAppointment } from '../../slices/appointmentsSlice.js';
import { patch } from '../../services/api/apiAppointment.js';
import { selectPractitioner } from '../../slices/practitionerSlice.js';
import TextTitle from '../elements/TextTitle.js';
import FormSuccess from '../elements/form/FormSuccess.js';
import PatientNotesEditor from './PatientNotesEditor.js';
import { selectAll as medicalHistoriesSelectAll } from '../../slices/medicalHistoriesSlice.js';
import { selectAll as menopauseSymptomsSelectAll } from '../../slices/menopauseSymptomsSlice.js';
import { selectAll as medicalProceduresSelectAll } from '../../slices/medicalProceduresSlice.js';
import { selectAll as contraceptionsSelectAll } from '../../slices/contraceptionsSlice.js';
import { selectAllItems as hrtSelectAllItems } from '../../slices/hrtsSlice.js';
import {
  getPopulatedDefaultGpLetterText,
  getPopulatedDefaultPatientNotesText,
} from './defaultText.js';

const AppointmentForm = (props) => {
  const { appointment, patient } = props;
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState();
  const [updatedMsg, setUpdatedMsg] = useState('');
  const [lastSaved, setLastSaved] = useState('');
  const practitioner = useSelector(selectPractitioner);
  const medicalHistories = useSelector(medicalHistoriesSelectAll);
  const menopauseSymptoms = useSelector(menopauseSymptomsSelectAll);
  const medicalProcedures = useSelector(medicalProceduresSelectAll);
  const contraceptions = useSelector(contraceptionsSelectAll);
  const hrtItems = useSelector(hrtSelectAllItems);
  const [saveButtonState, setSaveButtonState] = useState('disabled');
  const [changesUnsaved, setChangesUnsaved] = useState(false);

  const editorState = Array.isArray(appointment.practitionerNotesForPatient)
    ? EditorState.createWithContent(
        convertFromRaw(appointment.practitionerNotesForPatient[0].notes)
      )
    : EditorState.createWithContent(
        getPopulatedDefaultPatientNotesText({
          practitioner,
          patient,
          appointment,
          medicalHistories,
          menopauseSymptoms,
          medicalProcedures,
          contraceptions,
          hrtItems,
        })
      );

  const [showPatientNotes, setShowPatientNotes] = useState(
    Array.isArray(appointment.practitionerNotesForPatient)
  );

  const { register, handleSubmit, errors, control, setValue } = useForm({
    defaultValues: {
      practitionerNotesForPatient: editorState,
      practitionerNotesForGpLetter:
        appointment.practitionerNotesForGpLetter ||
        getPopulatedDefaultGpLetterText({ practitioner, patient }),
      practitionerNotesForClinic: Array.isArray(
        appointment.practitionerNotesForClinic
      )
        ? appointment.practitionerNotesForClinic[0].notes
        : '',
    },
  });

  const patchRequest = async (data) => {
    setUpdatedMsg('');
    try {
      const response = await patch(appointment._id, data);
      if (response.status && response.status === 'OK') {
        if (
          data.attributesToUpdate?.practitionerNotesForClinic?.length ||
          data.attributesToUpdate?.practitionerNotesForGpLetter?.length ||
          data.attributesToUpdate?.practitionerNotesForPatient?.blocks?.length
        ) {
          setChangesUnsaved(false);
          setUpdatedMsg('success');
          setLastSaved(dayjs().format('HH:mm DD/MM/YYYY'));
        }
        dispatch(replaceAppointment(response.appointment));
      } else {
        setUpdatedMsg('fail');
      }
    } catch (err) {
      setUpdatedMsg('fail');
      setApiError(
        'There was an error updating the appointment. Please try again.'
      );
    }
  };

  const onSubmitForm = async (data) => {
    setSaveButtonState('loading');
    const { practitionerNotesForPatient, ...attributesToUpdate } = data;
    try {
      await patchRequest({
        attributesToUpdate: {
          ...attributesToUpdate,
          practitionerNotesForPatient: data.practitionerNotesForPatient
            ? convertToRaw(practitionerNotesForPatient.getCurrentContent())
            : '',
        },
      });
    } catch (err) {
      setApiError(
        'There was an error updating the appointment. Please try again.'
      );
    }
    setSaveButtonState('enabled');
  };

  const onCloseAlertError = () => {
    setApiError(null);
  };

  const onClickAddPatientNotes = () => {
    setShowPatientNotes(true);
  };

  const onClickAddGPLetter = () => {
    const data = {
      gpLetterRequired: true,
    };

    patchRequest({
      attributesToUpdate: data,
    });
  };

  const onClickRemoveGPLetter = () => {
    const data = {
      practitionerNotesForGpLetter: '',
      gpLetterRequired: false,
    };

    patchRequest({
      attributesToUpdate: data,
    });

    setValue('practitionerNotesForGpLetter', '', {
      shouldDirty: true,
    });
  };

  const onChangeContent = () => {
    setSaveButtonState('enabled');
    setChangesUnsaved(true);
  };

  return (
    <div className="appointment-view-appointment-form">
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className="appointment-view-appointment-form__actions">
          {(lastSaved || changesUnsaved) && (
            <div>
              {changesUnsaved && (
                <div className="unsaved-changes">
                  Recent changes not yet saved
                </div>
              )}
              {lastSaved && <div>Last Saved: {lastSaved}</div>}
            </div>
          )}
          <Button
            type="submit"
            name="tester"
            className={saveButtonState === 'loading' ? 'loading' : ''}
            disabled={saveButtonState !== 'enabled'}
          >
            Save All
          </Button>
        </div>
        {updatedMsg && (
          <>
            {updatedMsg === 'success' && (
              <FormSuccess>Updated successfully</FormSuccess>
            )}
            {updatedMsg === 'fail' && (
              <FormError>Save not successful</FormError>
            )}
          </>
        )}
        <TextTitle
          type="sub"
          className="appointment-view-appointment-form__field-label appointment-view-appointment-form__field-label--top"
        >
          Patient Notes; this summary of the appointment will be shared in the
          patient’s account
        </TextTitle>

        {showPatientNotes ? (
          <PatientNotesEditor
            onChangeEditor={onChangeContent}
            control={control}
          />
        ) : (
          <Button onClick={onClickAddPatientNotes}>Add Patient Notes</Button>
        )}

        <TextTitle
          type="sub"
          className="appointment-view-appointment-form__field-label"
        >
          GP letter of recommended treatment; this will be made available to the
          patient to share with their GP.
        </TextTitle>

        {appointment.gpLetterRequired ? (
          <>
            <TextArea
              name="practitionerNotesForGpLetter"
              error={errors.practitionerNotesForGpLetter}
              registerRef={register()}
              onChange={onChangeContent}
              className="appointment-view-appointment-form-practitionerNotesForGpLetter"
            />
            <Button onClick={onClickRemoveGPLetter} color="danger-outline">
              Remove GP Letter
            </Button>
          </>
        ) : (
          <Button onClick={onClickAddGPLetter}>Add GP Letter</Button>
        )}

        <TextTitle
          type="sub"
          className="appointment-view-appointment-form__field-label"
        >
          Clinic GP Notes; these notes will be made available to other Health
          &amp; Her Menopause Specialist GPs
        </TextTitle>

        <TextArea
          name="practitionerNotesForClinic"
          error={errors.practitionerNotesForClinic}
          registerRef={register()}
          onChange={onChangeContent}
          className="appointment-view-appointment-form-practitionerNotesForClinic"
        />
      </form>

      {apiError && <AlertError label={apiError} onClose={onCloseAlertError} />}
    </div>
  );
};

AppointmentForm.propTypes = {
  appointment: PropTypes.object.isRequired,
  patient: PropTypes.object.isRequired,
};

export default AppointmentForm;
