import { baseApiUrl, jsonPatch, jsonGet } from './api.js';

export const patch = (appointmentId, data) => {
  const url = `${baseApiUrl}/appointments/${appointmentId}`;
  return jsonPatch(url, data);
};

export const get = (appointmentId) => {
  const url = `${baseApiUrl}/appointments/${appointmentId}`;
  return jsonGet(url);
};

export const getHistoryForPractitioner = (practitionerId) => {
  const url = `${baseApiUrl}/appointments/history/${practitionerId}`;
  return jsonGet(url);
};

export const getLetter = (appointmentId) => {
  const url = `${baseApiUrl}/appointments/${appointmentId}/gpletter`;
  return jsonGet(url);
};
