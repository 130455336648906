/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { LoadingSpinner } from '@hh/clinic-app-common';
import Button from '../elements/Button.js';
import AlertConfirm from '../elements/alert/Confirm.js';
import AlertError from '../elements/alert/Error.js';
import {
  AppointmentStatuses,
  replaceAppointment,
} from '../../slices/appointmentsSlice.js';
import { patch, getLetter } from '../../services/api/apiAppointment.js';

const WaitingOnPractitioner = (props) => {
  const { appointment } = props;
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState();
  const [showPublishConfirm, setShowPublishConfirm] = useState(false);
  const [loadingGpLetter, setLoadingGpLetter] = useState(false);

  const patchRequest = async (data) => {
    try {
      const response = await patch(appointment._id, data);
      dispatch(replaceAppointment(response.appointment));
    } catch (err) {
      setApiError(
        'There was an error updating the appointment. Please try again.'
      );
    }
  };

  const openGpLetter = async (appointmentId) => {
    setLoadingGpLetter(true);
    try {
      const gpLetterLink = await getLetter(appointmentId);
      window.open(gpLetterLink.url, '_blank');
    } catch (err) {
      setApiError(
        'There was an error opening the GP letter, please refresh the page and try again.'
      );
    }
    setLoadingGpLetter(false);
  };

  const onClickPublish = () => {
    setShowPublishConfirm(true);
  };

  const onPatientNoShowConfirmNo = () => {
    setShowPublishConfirm(false);
  };

  const onPatientNoShowConfirmYes = async () => {
    const attributesToUpdate = {
      status: AppointmentStatuses.COMPLETED,
    };

    patchRequest({ attributesToUpdate });
  };

  const onCloseAlertError = () => {
    setApiError(null);
  };

  let publishTitle = 'Publish Patient Notes';
  if (appointment.gpLetterRequired) {
    publishTitle += ' and GP letter';
  }
  publishTitle += '?';

  const publishInfo =
    'Publishing makes the Patient Notes and GP letter (if required) available to the patient. Once published, you will not be able to make additional edits to this appointment.';

  return (
    <>
      <div className="appointment-view__banner">
        <div>
          {publishTitle}
          <div className="appointment-view__banner-additional-text">
            {publishInfo}
          </div>
        </div>
        <div
          className="appointment-view__banner-actions"
          style={{ minWidth: '31rem' }}
        >
          {appointment.gpLetterRequired &&
            (loadingGpLetter ? (
              <LoadingSpinner />
            ) : (
              <Button
                color="primary-outline"
                className="appointment-view__banner-action"
                onClick={() => openGpLetter(appointment._id)}
              >
                Preview GP Letter
              </Button>
            ))}

          <Button
            color="primary"
            onClick={onClickPublish}
            className="appointment-view__banner-action"
          >
            Yes, Publish
          </Button>
        </div>
      </div>

      {apiError && <AlertError label={apiError} onClose={onCloseAlertError} />}

      {showPublishConfirm && (
        <AlertConfirm
          title="Confirm Publish"
          label="Publishing makes the patient notes and GP letter available to the
            patient. Once published, you will not be able to make additional
            edits to this appointment. Please ensure you have clicked [Save All] before publishing. Continue with publishing?"
          onNo={onPatientNoShowConfirmNo}
          onYes={onPatientNoShowConfirmYes}
        />
      )}
    </>
  );
};

WaitingOnPractitioner.propTypes = {
  appointment: PropTypes.object.isRequired,
};

export default WaitingOnPractitioner;
