import './Login.scss';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Auth } from '@aws-amplify/auth';
import { Login as LoginForm } from '@hh/clinic-app-common';
import PageHeader from '../../elements/PageHeader.js';
import { fetchPractitioner } from '../../../slices/practitionerSlice.js';
import TextTitle from '../../elements/TextTitle.js';
import Button from '../../elements/Button.js';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onComplete = () => {
    dispatch(fetchPractitioner());
    history.push('/practitioner/P4hR9rfXEVpoprOs6m0pJR9LultTHbO1');
  };
  const pageHeader = (showBack, onClickBack) => (
    <>
      <PageHeader
        showBack
        title={showBack ? 'Back' : 'Clinic Practitioner'}
        onClickBack={showBack ? onClickBack : undefined}
      />
    </>
  );

  const beforeLoginForm = () => <TextTitle>Practitioner Login</TextTitle>;
  const afterLoginForm = () => (
    <Link
      to="/practitioner/P4hR9rfXEVpoprOs6m0pJR9LultTHbO1/forgotten-password"
      className="login-actions"
    >
      <Button color="primary-outline">Forgotten Password</Button>
    </Link>
  );
  const loginEmailPasswordRequest = async (
    email,
    password,
    setRequestInProgress,
    onStageComplete,
    setGlobalFormError
  ) => {
    setRequestInProgress(true);
    try {
      let user;
      await Auth.signIn(email, password).then((u) => {
        user = u;
      });
      onStageComplete({ email, user });
    } catch (err) {
      setGlobalFormError(<p>Incorrect login details</p>);
      window.scrollTo(0, 0);
      setRequestInProgress(false);
    }
  };
  const login2FactorRequest = async (
    user,
    login2FactorCode,
    setRequestInProgress,
    onStageComplete,
    setGlobalFormError
  ) => {
    setRequestInProgress(true);
    try {
      await Auth.sendCustomChallengeAnswer(user, login2FactorCode);
      onStageComplete();
    } catch (err) {
      setGlobalFormError(
        <p>
          Incorrect login code. Please check your email and try again. If the
          problem continues please email{' '}
          <a href="mailto:clinic@healthandher.com">clinic@healthandher.com</a>.
        </p>
      );
      setRequestInProgress(false);
      window.scrollTo(0, 0);
    }
  };
  return (
    <div className="login">
      <LoginForm
        useState={useState}
        useForm={useForm}
        pageHeader={pageHeader}
        onComplete={onComplete}
        beforeLoginForm={beforeLoginForm}
        afterLoginForm={afterLoginForm}
        login2FactorRequest={login2FactorRequest}
        loginEmailPasswordRequest={loginEmailPasswordRequest}
      />
    </div>
  );
};

export default Login;
