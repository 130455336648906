import { createSlice } from '@reduxjs/toolkit';

export const practitionersSlice = createSlice({
  name: 'practitioners',
  initialState: {
    fetched: false,
    entities: [],
  },
  // Redux Toolkit allows us to write "mutating" logic in reducers. It
  // doesn't actually mutate the state because it uses the Immer library,
  // which detects changes to a "draft state" and produces a brand new
  // immutable state based off those changes
  reducers: {
    replacePractitioners: (state, action) => {
      state.entities = action.payload;
    },
    addPractitioner: (state, action) => {
      state.entities.push(action.payload);
    },
    replacePractitioner: (state, action) => {
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      if (index !== -1) {
        state.entities[index] = action.payload;
      } else {
        state.entities.push(action.payload);
      }
    },
  },
});

export const { replacePractitioners, addPractitioner, replacePractitioner } =
  practitionersSlice.actions;

// Selectors ******************************************************************
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export const selectAllPractitioners = (state) => {
  return state.practitioners.entities;
};

export default practitionersSlice.reducer;
