import { configureStore } from '@reduxjs/toolkit';
import patientsReducer from '../slices/patientsSlice.js';
import practitionerReducer from '../slices/practitionerSlice.js';
import practitionersReducer from '../slices/practitionersSlice.js';
import medicalHistoriesReducer from '../slices/medicalHistoriesSlice.js';
import menopauseSymptomsReducer from '../slices/menopauseSymptomsSlice.js';
import medicalProceduresReducer from '../slices/medicalProceduresSlice.js';
import contraceptionsReducer from '../slices/contraceptionsSlice.js';
import hrtsReducer from '../slices/hrtsSlice.js';
import appointmentsReducer from '../slices/appointmentsSlice.js';
import contactTopicReducer from '../slices/contactTopicsSlice.js';

export default configureStore({
  reducer: {
    patients: patientsReducer,
    practitioner: practitionerReducer,
    practitioners: practitionersReducer,
    medicalHistories: medicalHistoriesReducer,
    menopauseSymptoms: menopauseSymptomsReducer,
    medicalProcedures: medicalProceduresReducer,
    contraceptions: contraceptionsReducer,
    hrts: hrtsReducer,
    appointments: appointmentsReducer,
    contactTopics: contactTopicReducer,
  },
});
