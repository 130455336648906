import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import Button from '../elements/Button.js';

dayjs.extend(advancedFormat);

const AppointmentTable = (props) => {
  const {
    onClickAppointment,
    startAt,
    practitionerFullname,
    patientFullname,
    showPractitioner,
    status,
  } = props;
  return (
    <tr className="upcoming-appointment" onClick={onClickAppointment}>
      <td>{patientFullname}</td>
      <td>
        {startAt.format('HH:mm')}
        {' - '}
        {startAt.format('DD-MM-YYYY')}
      </td>
      <td>{status}</td>
      {showPractitioner && <td>{practitionerFullname}</td>}
      <td>
        <Button onClick={onClickAppointment}>View Appointment</Button>
      </td>
    </tr>
  );
};

AppointmentTable.propTypes = {
  onClickAppointment: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  startAt: PropTypes.object.isRequired,
  practitionerFullname: PropTypes.string.isRequired,
  patientFullname: PropTypes.string.isRequired,
  showPractitioner: PropTypes.bool,
  status: PropTypes.string.isRequired,
};

AppointmentTable.defaultProps = {
  showPractitioner: false,
};

export default AppointmentTable;
