import './NavBar.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home.js';
import HistoryIcon from '@material-ui/icons/History';
import AccountCircleIcon from '@material-ui/icons/AccountCircle.js';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Auth } from '@aws-amplify/auth';

const NavBar = (props) => {
  const { active } = props;

  const classes = ['navigation-nav-bar'];

  const onClickLogout = async () => {
    try {
      await Auth.signOut();
      // Perform a full page refresh here so we clear out all in memory
      // data stored in redux
      window.location = '/practitioner/P4hR9rfXEVpoprOs6m0pJR9LultTHbO1';
    } catch (err) {
      console.log('API: Error logging out');
    }
  };

  return (
    <div className={classes.join(' ')}>
      <Link
        to="/practitioner/P4hR9rfXEVpoprOs6m0pJR9LultTHbO1"
        className={`navigation-nav-bar__tab ${
          active === 'HOME' ? 'navigation-nav-bar__tab--active' : ''
        }`}
      >
        <HomeIcon className="navigation-nav-bar__icon" />
        <div className="navigation-nav-bar__label">Home</div>
      </Link>
      <Link
        to="/practitioner/P4hR9rfXEVpoprOs6m0pJR9LultTHbO1/history"
        className={`navigation-nav-bar__tab ${
          active === 'HISTORY' ? 'navigation-nav-bar__tab--active' : ''
        }`}
      >
        <HistoryIcon className="navigation-nav-bar__icon" />
        <div className="navigation-nav-bar__label">History</div>
      </Link>
      <Link
        to="/practitioner/P4hR9rfXEVpoprOs6m0pJR9LultTHbO1/account"
        className={`navigation-nav-bar__tab ${
          active === 'ACCOUNT' ? 'navigation-nav-bar__tab--active' : ''
        }`}
      >
        <AccountCircleIcon className="navigation-nav-bar__icon" />
        <div className="navigation-nav-bar__label">Account</div>
      </Link>
      <div className="navigation-nav-bar__tab" onClick={onClickLogout}>
        <ExitToAppIcon className="navigation-nav-bar__icon" />
        <div className="navigation-nav-bar__label">Logout</div>
      </div>
    </div>
  );
};

NavBar.propTypes = {
  active: PropTypes.oneOf(['HOME', 'HISTORY', 'ACCOUNT', null]),
};

NavBar.defaultProps = {
  active: null,
};

export default NavBar;
