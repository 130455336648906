import './Appointment.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import TextBody from '../elements/TextBody.js';
import AppointmentTable from '../appointment/AppointmentTable.js';
import { AppointmentStatuses } from '../../slices/appointmentsSlice.js';
import { selectAllPractitioners } from '../../slices/practitionersSlice.js';

dayjs.extend(advancedFormat);

const Appointment = (props) => {
  const { appointment, showPractitioner, patient, view } = props;
  const history = useHistory();
  const practitioners = useSelector(selectAllPractitioners);

  const practitioner = practitioners.find(
    (el) => el._id === appointment.practitionerId
  );

  if (!patient) {
    return null;
  }

  console.log('test');

  const onClickAppointment = () => {
    history.push(
      `/practitioner/P4hR9rfXEVpoprOs6m0pJR9LultTHbO1/appointments/${appointment._id}`
    );
  };

  const startAt = dayjs(appointment.startAt);
  const endAt = dayjs(appointment.endAt);

  const patientFullname = [patient.firstName, patient.lastName].join(' ');

  const actionRequired =
    appointment.status === AppointmentStatuses.WAITING_ON_PRACTITIONER;

  const practitionerFullname = [
    practitioner.title,
    practitioner.firstName,
    practitioner.lastName,
  ].join(' ');

  return view === 'table' ? (
    <AppointmentTable
      startAt={startAt}
      onClickAppointment={onClickAppointment}
      patientFullname={patientFullname}
      practitionerFullname={practitionerFullname}
      showPractitioner={showPractitioner}
      status={appointment.status}
    />
  ) : (
    <div className="upcoming-appointment" onClick={onClickAppointment}>
      <div className="upcoming-appointment__info">
        <div className="upcoming-appointment__details">
          <TextBody className="upcoming-appointment__date">
            {startAt.format('dddd Do MMMM')}
          </TextBody>
          <TextBody className="upcoming-appointment__time">
            {startAt.format('h:mm A')} - {endAt.format('h:mm A')}
          </TextBody>
          <TextBody className="upcoming-appointment__patient">
            <strong>Patient</strong> {patientFullname}
          </TextBody>
          {showPractitioner && (
            <TextBody className="upcoming-appointment__practitioner">
              <strong>Practitioner</strong> {practitionerFullname}
            </TextBody>
          )}
        </div>
      </div>
      {actionRequired && (
        <div className="upcoming-appointment__action-required">
          Action: Patient notes and GP letter need to be published.
        </div>
      )}
      <div className="upcoming-appointment__actions">
        <div className="upcoming-appointment__action">View Appointment</div>
      </div>
    </div>
  );
};

Appointment.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  appointment: PropTypes.object.isRequired,
  showPractitioner: PropTypes.bool,
  view: PropTypes.oneOf(['table', 'card']),
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object.isRequired,
};

Appointment.defaultProps = {
  showPractitioner: false,
  view: 'card',
};

export default Appointment;
