import './PageHeader.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration.js';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import NavBar from '../navigation/NavBar.js';
import Logo from './Logo.js';
import TextTitle from './TextTitle.js';
import { selectUpcomingAppointments } from '../../slices/appointmentsSlice.js';
import { selectAllPatients } from '../../slices/patientsSlice.js';
import { selectPractitionerIsAuthenticated } from '../../slices/practitionerSlice.js';

dayjs.extend(duration);

const PageHeader = (props) => {
  const { title, showBack, onClickBack } = props;
  const [currentTime, setCurrentTime] = useState(dayjs());
  const [nextAppointmentIn, setNextAppointmentIn] = useState();
  const history = useHistory();
  const patients = useSelector(selectAllPatients);
  const practitionerIsAuthenticated = useSelector(
    selectPractitionerIsAuthenticated
  );

  const upcomingAppointments = useSelector(selectUpcomingAppointments);

  const nextAppointment =
    upcomingAppointments.length > 0 ? upcomingAppointments[0] : null;

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      setCurrentTime(dayjs());

      if (nextAppointment) {
        const startAt = dayjs(nextAppointment.startAt);

        const durationToNextAppointment = dayjs.duration(startAt.diff(dayjs()));
        const days = durationToNextAppointment.days();
        const hours = durationToNextAppointment.hours();

        if (days > 0) {
          setNextAppointmentIn(`${days}d ${hours}h`);
        } else {
          const mins = durationToNextAppointment.minutes();
          setNextAppointmentIn(`${hours}h ${mins}m`);
        }
      }
    }, 200);

    return () => window.clearTimeout(timeout);
  }, [currentTime, nextAppointment]);

  // eslint-disable-next-line no-unused-vars
  const renderNextAppointment = () => {
    if (nextAppointmentIn === undefined) return null;

    const patient = patients.find((el) => el._id === nextAppointment.patientId);

    return (
      <div className="page-header-info__block">
        <div className="page-header-info__title">Next Appointment in</div>
        <div className="page-header-info__time">{nextAppointmentIn}</div>
        <div className="page-header-info__extra">
          {patient.firstName} {patient.lastName}
        </div>
      </div>
    );
  };

  const renderLoggedInHeader = () => {
    return (
      <>
        <div className="page-header-info">
          <div className="page-header-info__block">
            <div className="page-header-info__extra">
              Today: {currentTime.format('ddd Do MMM')}
            </div>
            <div className="page-header-info__time">
              {currentTime.format('HH:mm:ss')}
            </div>
          </div>
        </div>
        <NavBar />
      </>
    );
  };

  const onClickLogo = () => {
    history.push('/practitioner/P4hR9rfXEVpoprOs6m0pJR9LultTHbO1');
  };

  let pageTitle = title;
  if (showBack && pageTitle.length === 0) {
    pageTitle = <span className="page-header__back-title">Back</span>;
  }

  return (
    <div className="page-header">
      <div className="page-header__left">
        {showBack && !!onClickBack ? (
          <ArrowBackIosIcon
            className="page-header__back"
            onClick={onClickBack}
          />
        ) : null}
        <TextTitle className="page-header__title" onClick={onClickBack}>
          {pageTitle}
        </TextTitle>
        <button
          type="button"
          className="page-header__logo"
          onClick={onClickLogo}
        >
          <Logo />
        </button>
      </div>
      {practitionerIsAuthenticated && renderLoggedInHeader()}
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  showBack: PropTypes.bool,
  onClickBack: PropTypes.func,
};

PageHeader.defaultProps = {
  title: '',
  showBack: false,
  onClickBack: null,
};

export default PageHeader;
