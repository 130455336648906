import './PatientNotesEditor.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Editor } from 'draft-js';

function PatientNotesEditor(props) {
  const { control, onChangeEditor } = props;

  return (
    <div className="patient-notes-editor">
      <Controller
        name="practitionerNotesForPatient"
        control={control}
        render={({ value, onChange }) => {
          return (
            <Editor
              editorState={value}
              spellCheck
              onChange={(event) => {
                if (
                  event.getCurrentContent().getPlainText() !==
                  value.getCurrentContent().getPlainText()
                )
                  onChangeEditor();
                onChange(event, value);
              }}
            />
          );
        }}
      />
    </div>
  );
}

PatientNotesEditor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  onChangeEditor: PropTypes.func,
};

PatientNotesEditor.defaultProps = {
  onChangeEditor: null,
};

export default PatientNotesEditor;
